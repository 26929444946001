// HMIDraw 绘制处理js
//
//  依赖
//      HMIEvent.js
//      HMIProject.js
//
//  历史
//      创建    LEE     2020/12/25
//=============================================================================
import HG from './HMIGlobal.js'
import HMIPrj from "./HMIProject.js";
import HMIEvents from "./HMIEvents.js";
import HMIDataPool from './HMIDataPool.js'
import HMIExterCtrls from './HMIExterCtrls.js'
import HMIDOMCtrlMG from './HMIDOMCtrlMG.js'
import $store from '../../store/store'
// import HMIEchoData from './HMIEchoData.js'
// import constant from '../constant.js'
// import $store from '../../store/store'
//-----------------------------------------------------------------------------
// 绘制实例
//
var gDrawHandle = {
    //
    // 属性
    //
    // canvas
    canvas: null,
    ctx: null,
    // canvas buffer
    canvasBuf: null,
    ctxBuf: null,
    // canvas 位置
    rectCanvasArea: {
        l: 0, t: 0, w: 0, h: 0,
    },
    // 初始化是否完成
    initSuccess: false,
    // 背景图片
    bgImg: null,
    // 选中区域
    selected: false,
    // 选中开始、结束点
    selectedPointSt: { x: -1, y: -1, },
    selectedPointEd: { x: -1, y: -1, },
    // 选中区域
    selectedRect: { l: -1, t: -1, w: -1, h: -1 },
    // 控件选中
    ctrlselected: { selected: false, status: 0 },
    // 控件选中时移动的偏移
    ctrlselectedMove: { x: -1, y: -1, },
    // 鼠标是否在可执行控件上方
    onExecableCtrl: false,
    // 界面操作活动状态
    eventActivate: true,
    // 界面操作计数
    eventActivateCnt: 100,
    // 是否处于编辑模式下
    editingMode: false,
    // 数据是否刷新
    valueNew: false,
    // 父窗口的DOM
    parentCtrl: null,
    // 临时显示用的DOM控件用绘制层索引
    singleDisplayDomCtrlZIdx: 100,
    // 上次绘制的页面索引
    lastDrawPageIdx: -1,
    // 绘制页面改变
    drawPrjPageChanged: true,
    // 显示画布的背景色
    dspCanvasBgColor: '#DFDFDF',

    domZoom:1,
    // 设置
    settings: {
        // 初始尺寸
        width: 1440,
        height: 1000,
        // 上次绘制的尺寸
        lastW: 1440,
        lastH: 1000,
        // 工程尺寸缩放后的比率
        zoomPrjRateW: 0,
        zoomPrjRateH: 0,
        // 滚动位置
        scrollPosLeftRate: 0,
        scrollPosTopRate: 0,
        // 当前屏幕可视范围
        displayWidth: 0,
        displayHeight: 0,
        // 刷新帧数 活动 对应定时间隔
        fpsInterval: 50,
        // 刷新帧数 静态 对应定时间隔
        fpsStaticInterval: 500,
        // 刷新活动计数 10秒后 停止活动状态
        activeCntMax: 10,
        // 设备缩放率
        deviceZoom: 1,
        // 显示缩放率
        displayZoom: 1,
        // 显示缩放率改变
        displayZoomChanged: false,
        //父窗口缩放率
        domZoom: 1,
    },

    //-----------------------------------------------------------------------------
    // 函数
    //
    //-----------------------------------------------------------------------------
    // 初始化
    //
    init: function (elName) {
        // 父窗口的DOM
        this.parentCtrl = document.getElementById(elName);
        // 检查是否存在
        if (this.parentCtrl) {

            // 添加canvas
            this.canvasBuf = document.createElement('canvas');

            // 获取context
            this.ctxBuf = this.canvasBuf.getContext('2d');
            // 当前设备缩放率
            this.settings.deviceZoom = this.calcDeviceZoom(this.ctxBuf);

            this.settings.domZoom = 1;
            if (!this.editingMode) {
                //父窗口dom的缩放率
                this.settings.domZoom = this.calcDomZoom(this.parentCtrl)
                //设定父窗口的高度
                this.parentCtrl.style.height = 700 * this.settings.domZoom + 'px';
                // HMIZoombar.setZoomData()
            }
            // 添加canvas
            this.canvas = document.createElement('canvas');
            // 绘制主canvas id
            this.canvas.id = HG.$Set.allCanvasID.main;
            // 获取context
            this.ctx = this.canvas.getContext('2d');

            // 添加到对象中
            this.parentCtrl.appendChild(this.canvas);

            // 滚动位置重置
            this.settings.scrollPosLeftRate = 0;
            this.settings.scrollPosTopRate = 0;

            // 设备缩放率改变
            this.deviceZoomRateChanged();
            // 画布偏移位置计算
            this.calcCanvasOffsideRect();

            // 窗口大小改变
            this.drawSizeChanged(window.innerWidth, window.innerHeight);
            // 注册窗口大小改变事件
            HMIEvents.attributes.addResizeFunc(this.drawSizeChanged);
        }
        // 设置父控件
        HMIDOMCtrlMG.parentCtrl = this.parentCtrl;
    },
    //-----------------------------------------------------------------------------
    // 计算父窗口缩放率
    //
    calcDomZoom: function (parentCtrl) {
        return parentCtrl.style.width.split('p')[0] / 1280;
    },

    //-----------------------------------------------------------------------------
    // 计算设备缩放率
    //
    calcDeviceZoom: function (context) {
        // 设备缩放率
        var backingStore = context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio || 1;
            let zoom= backingStore/(window.devicePixelRatio || 1);
            if (window.devicePixelRatio!=1) {
                zoom=1;
            }
        return  zoom;
    },
    //-----------------------------------------------------------------------------
    // 设备缩放率改变
    //
    deviceZoomRateChanged: function () {
        
        // 计算最大尺寸
        this.settings.width = window.screen.width * this.settings.deviceZoom*this.settings.domZoom;
        this.settings.height = window.screen.height * this.settings.deviceZoom*this.settings.domZoom;
        // 检查设备缩放尺寸
        if (this.settings.deviceZoom < 1) {
            // 计算最大尺寸
            this.settings.width = window.screen.width / this.settings.deviceZoom*this.settings.domZoom;
            this.settings.height = window.screen.height / this.settings.deviceZoom*this.settings.domZoom;
        } else if (this.settings.deviceZoom == 1) {
            // 计算最大尺寸
            this.settings.width = window.screen.width * 1.5*this.settings.domZoom;
            this.settings.height = window.screen.height * 1.5*this.settings.domZoom;
        }

        // 缓存尺寸
        this.canvasBuf.width = this.settings.width;
        this.canvasBuf.height = this.settings.height;
        // 显示尺寸
        this.canvas.width = this.settings.width;
        this.canvas.height = this.settings.height;
        // 检查缩放
        if (this.settings.deviceZoom != 1) {
            // 样式显示
            this.canvas.style.width = (this.settings.width / this.settings.deviceZoom) + 'px';
            this.canvas.style.height = (this.settings.height / this.settings.deviceZoom) + 'px';
        } else {
            // 样式显示
            this.canvas.style.width = this.settings.width + 'px';
            this.canvas.style.height = this.settings.height + 'px';
        }
    },
    //-----------------------------------------------------------------------------
    // 画布偏移位置计算
    //
    calcCanvasOffsideRect: function () {
        // 位置
        this.rectCanvasArea.l = this.canvas.getBoundingClientRect().x;
        this.rectCanvasArea.t = this.canvas.getBoundingClientRect().y;
        this.rectCanvasArea.w = this.canvas.getBoundingClientRect().width;
        this.rectCanvasArea.h = this.canvas.getBoundingClientRect().height;

        // 检查是否兼容getBoundingClientRect()
        if ((!this.rectCanvasArea.l) && (!this.rectCanvasArea.t)) {
            // 位置
            this.rectCanvasArea.l = this.canvas.getBoundingClientRect().left;
            this.rectCanvasArea.t = this.canvas.getBoundingClientRect().top;
        }

        // 去除强制设置位置
        //this.rectCanvasArea.l = 240
    },
    //-----------------------------------------------------------------------------
    // 获取尺寸缩放
    //
    getRealSizeRate: function () {
        // 获取全局实际缩放率
        return this.settings.deviceZoom / this.settings.displayZoom;
    },
    //-----------------------------------------------------------------------------
    // 设置显示缩放率
    //
    setDisplayZoom: function (vDisplayZoom) {
        // 检查是否一致
        if (vDisplayZoom != this.settings.displayZoom) {
            // 获取全局实际缩放率
            this.settings.displayZoom = vDisplayZoom;
            // 显示缩放率改变
            this.settings.displayZoomChanged = true;
        }
    },
    //-----------------------------------------------------------------------------
    // 获取显示缩放率是否改变，并重置
    //
    getAndResetDisplayZoomChanged: function () {
        // 获取是否改变
        let lChanged = this.settings.displayZoomChanged;
        // 重置
        this.settings.displayZoomChanged = false;

        return lChanged;
    },
    //-----------------------------------------------------------------------------
    // 窗口大小改变 需要外部调用，类似静态函数
    //
    drawSizeChanged: function (width, height) {
        // 窗口大小改变
        let displayWidth = null;
        let displayHeight = null;
        // 检查是否定义
        if (gDrawHandle.settings) {
            // 检查是否为编辑模式
            if (gDrawHandle.editingMode) {
                // 窗口大小改变
                displayWidth = (width - HG.$Set.allSettings.scrollSize.scrollHMargin) * gDrawHandle.settings.deviceZoom;
                displayHeight = (height - HG.$Set.allSettings.scrollSize.scrollVMargin) * gDrawHandle.settings.deviceZoom;
            } else {
                // 窗口大小改变
                displayWidth = (width - HG.$Set.allSettings.scrollSize.scrollHMarginExe) * gDrawHandle.settings.deviceZoom;
                displayHeight = (height - HG.$Set.allSettings.scrollSize.scrollVMarginExe) * gDrawHandle.settings.deviceZoom;
            }
            // 检查是否有效
            if ((displayWidth > 100) && (displayHeight > 50)) {
                // 当前屏幕可视范围
                gDrawHandle.settings.displayWidth = displayWidth;
                gDrawHandle.settings.displayHeight = displayHeight;
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制前准备
    //
    onPrepareDraw: function () {
        let vCurrentZoom = 0;

        // 检查画布是否有效
        if (!this.canvas) return;

        // 当前缩放率
        vCurrentZoom = this.calcDeviceZoom(this.ctxBuf);
        // 检查缩放率是否改变
        if (this.settings.deviceZoom != vCurrentZoom) {
            // 设置
            this.settings.deviceZoom = vCurrentZoom;
            // 设备缩放率改变
            this.deviceZoomRateChanged();
        }

        // 画布偏移位置计算
        this.calcCanvasOffsideRect();
    },
    //-----------------------------------------------------------------------------
    // 绘制画布的鼠标样式
    //
    setCanvasCursor: function (waitStatus = false) {
        // 检查画布是否有效
        if (!this.canvas) return;

        // 检查是否选中
        if (this.ctrlselected.selected) {
            // 检查状态
            switch (this.ctrlselected.status) {
                case 1:
                    // 鼠标样式
                    this.canvas.style.cursor = 'nwse-resize';
                    break;
                case 2:
                    // 鼠标样式
                    this.canvas.style.cursor = 'nesw-resize';
                    break;
                case 3:
                    // 鼠标样式
                    this.canvas.style.cursor = 'nwse-resize';
                    break;
                case 4:
                    // 鼠标样式
                    this.canvas.style.cursor = 'nesw-resize';
                    break;
                default:
                    // 鼠标样式
                    this.canvas.style.cursor = 'move';
                    break;
            }
        }
        else {
            // 是否编辑模式
            if (this.editingMode) {
                // 鼠标样式
                this.canvas.style.cursor = '';
            } else {
                // 鼠标是否在可执行控件上方
                if (this.onExecableCtrl) {
                    // 检查是否为鼠标等待状态
                    if (waitStatus) {
                        // 鼠标按下时 进入到等待样式
                        // 鼠标样式
                        this.canvas.style.cursor = 'wait';
                    } else {
                        // 其他状态
                        // 鼠标样式
                        this.canvas.style.cursor = 'pointer';
                    }
                } else {
                    // 鼠标样式
                    this.canvas.style.cursor = '';
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制缓存
    //
    drawBuffer: function () {
        // 缩放宽度、高度
        let vCurZoomW = this.settings.width * this.settings.displayZoom;
        let vCurZoomH = this.settings.height * this.settings.displayZoom;
        let vCurLeft = 0, vCurTop = 0;

        // 检查缩放尺寸
        if ((this.settings.lastW > vCurZoomW) && (this.settings.lastH > vCurZoomH)) {
            // 清除上次绘制
            this.ctx.fillStyle = this.dspCanvasBgColor;
            this.ctx.fillRect(0, 0, this.settings.lastW, this.settings.lastH);
        }
        // 检查是否显示全部
        if ((this.settings.scrollPosLeftRate < 1) && (this.settings.scrollPosTopRate < 1)) {
            // 位置计算
            vCurLeft = this.settings.scrollPosLeftRate * this.settings.width;
            vCurTop = this.settings.scrollPosTopRate * this.settings.height;
            // 绘制缓存
            this.ctx.drawImage(this.canvasBuf,
                vCurLeft, vCurTop, this.settings.width, this.settings.height,
                0, 0, vCurZoomW, vCurZoomH);
        }

        // 复制上次绘制的尺寸
        this.settings.lastW = vCurZoomW;
        this.settings.lastH = vCurZoomH;
    },
    //-----------------------------------------------------------------------------
    // 绘制背景
    //
    drawBackground: function (vPrj) {
        
        // 当前页面
        let vPage = vPrj.allPg[vPrj.curPg];
        let vZoomPrjW = vPrj.setting.width * this.settings.displayZoom * this.settings.domZoom;
        let vZoomPrjH = vPrj.setting.height * this.settings.displayZoom * this.settings.domZoom;
        // 检查比率
        if ((gDrawHandle.settings.displayWidth > 0) && (gDrawHandle.settings.displayHeight > 0)) {
            // 横向显示比率
            this.settings.zoomPrjRateW = Math.round(1000 * vZoomPrjW / gDrawHandle.settings.displayWidth);
            this.settings.zoomPrjRateW /= 1000;
            // 纵向显示比率
            this.settings.zoomPrjRateH = Math.round(1000 * vZoomPrjH / gDrawHandle.settings.displayHeight);
            this.settings.zoomPrjRateH /= 1000;
        }

        // 背景颜色
        this.ctxBuf.fillStyle = this.dspCanvasBgColor;
        // 清空全部画面区域
        this.ctxBuf.fillRect(0, 0, this.settings.width, this.settings.height);
        
        // 检查是否存在背景图
        if (vPage.bgImage === '' || vPage.bgImage == null) {
            // 背景颜色
            this.ctxBuf.fillStyle = vPage.bgColor;
            
            // 清空区域
            this.ctxBuf.fillRect(0, 0, vPrj.setting.width * this.settings.domZoom, vPrj.setting.height * this.settings.domZoom);
        }
        else {
            // 获取图片
            let vImg = this.getImage(vPage.bgImage);
            // 绘制图片
            if (vPage.bgImage) {
                this.ctxBuf.drawImage(vImg, 0, 0, vPrj.setting.width * this.settings.domZoom, vPrj.setting.height * this.settings.domZoom);
            }

            let pattern = this.ctxBuf.createPattern(vImg, 'no-repeat');
            this.ctxBuf.fillS = pattern;
        }

    },
    //-----------------------------------------------------------------------------
    // 绘制选择小框
    //
    drawLittleFrame: function (x, y) {
        // 检查全设置是否有效
        if (HG) {
            // 小框填充
            this.ctxBuf.fillRect(x - HG.$Set.allSettings.selectedFrameSetting.halfSize,
                y - HG.$Set.allSettings.selectedFrameSetting.halfSize,
                HG.$Set.allSettings.selectedFrameSetting.size,
                HG.$Set.allSettings.selectedFrameSetting.size);
            // 小框绘制
            this.ctxBuf.strokeRect(x - HG.$Set.allSettings.selectedFrameSetting.halfSize,
                y - HG.$Set.allSettings.selectedFrameSetting.halfSize,
                HG.$Set.allSettings.selectedFrameSetting.size,
                HG.$Set.allSettings.selectedFrameSetting.size);
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制外框
    //
    drawFrame: function (l, t, w, h, mainFrame = false) {
        // 保存
        this.ctxBuf.save();
        // 颜色
        this.ctxBuf.strokeStyle = '#FFFFFF';
        this.ctxBuf.lineWidth = 1;
        // 绘制矩形
        this.ctxBuf.strokeRect(l, t, w, h);

        // 颜色
        this.ctxBuf.strokeStyle = '#000000';
        this.ctxBuf.lineWidth = 1;
        this.ctxBuf.setLineDash([10, 8]);
        // 绘制矩形
        this.ctxBuf.strokeRect(l, t, w, h);

        // 检查是否为主控件框
        if (mainFrame) {
            // 颜色
            this.ctxBuf.fillStyle = '#3F546E';
        } else {
            // 颜色
            this.ctxBuf.fillStyle = '#FFFFFF';
        }
        this.ctxBuf.setLineDash([]);
        // 角落小框绘制
        this.drawLittleFrame(l, t);
        this.drawLittleFrame(l + w, t);
        this.drawLittleFrame(l, t + h);
        this.drawLittleFrame(l + w, t + h);
        // 恢复
        this.ctxBuf.restore();
    },
    //-----------------------------------------------------------------------------
    // 绘制直线控件
    //
    drawLine: function (vCtrlLine) {
        // 检查控件类型
        if (vCtrlLine.type != HG.ControlType.line) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlLine.foreColor;
        this.ctxBuf.lineWidth = vCtrlLine.lineWidth;
        // 绘制直线
        this.ctxBuf.beginPath();
        // 检查类型
        switch (vCtrlLine.lineType) {
            default:
            case HG.AttrSelType.LineStyleType.lineH:
                // 水平线段
                this.ctxBuf.moveTo(vCtrlLine.x, vCtrlLine.y);
                this.ctxBuf.lineTo(vCtrlLine.x + vCtrlLine.w, vCtrlLine.y);
                break;
            case HG.AttrSelType.LineStyleType.lineV:
                // 垂直线段
                this.ctxBuf.moveTo(vCtrlLine.x, vCtrlLine.y);
                this.ctxBuf.lineTo(vCtrlLine.x, vCtrlLine.y + vCtrlLine.h);
                break;
            case HG.AttrSelType.LineStyleType.lineCross1:
                // 交叉1
                this.ctxBuf.moveTo(vCtrlLine.x, vCtrlLine.y);
                this.ctxBuf.lineTo(vCtrlLine.x + vCtrlLine.w, vCtrlLine.y + vCtrlLine.h);
                break;
            case HG.AttrSelType.LineStyleType.lineCross2:
                // 交叉2
                this.ctxBuf.moveTo(vCtrlLine.x, vCtrlLine.y + vCtrlLine.h);
                this.ctxBuf.lineTo(vCtrlLine.x + vCtrlLine.w, vCtrlLine.y);
                break;
        }
        // 绘制
        this.ctxBuf.stroke();
        this.ctxBuf.closePath();
    },
    //-----------------------------------------------------------------------------
    // 绘制矩形控件
    //
    drawRect: function (vCtrlRect) {
        // 检查控件类型
        if (vCtrlRect.type != HG.ControlType.rect) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlRect.foreColor;
        this.ctxBuf.fillStyle = vCtrlRect.bgColor;
        this.ctxBuf.lineWidth = vCtrlRect.lineWidth;
        // 绘制矩形
        this.ctxBuf.fillRect(vCtrlRect.x, vCtrlRect.y, vCtrlRect.w, vCtrlRect.h);
        this.ctxBuf.strokeRect(vCtrlRect.x, vCtrlRect.y, vCtrlRect.w, vCtrlRect.h);
    },
    //-----------------------------------------------------------------------------
    // 绘制圆控件
    //
    drawCircle: function (vCtrlCircle) {
        // 检查控件类型
        if (vCtrlCircle.type != HG.ControlType.circle) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlCircle.foreColor;
        this.ctxBuf.fillStyle = vCtrlCircle.bgColor;
        this.ctxBuf.lineWidth = vCtrlCircle.lineWidth;
        // 绘制圆
        this.ctxBuf.beginPath();
        this.ctxBuf.arc(vCtrlCircle.x + (vCtrlCircle.w / 2),
            vCtrlCircle.y + (vCtrlCircle.h / 2),
            Math.min((vCtrlCircle.w / 2), (vCtrlCircle.h / 2)), 0, 2 * Math.PI);
        // 绘制
        this.ctxBuf.fill();
        this.ctxBuf.stroke();
    },
    //-----------------------------------------------------------------------------
    // 绘制弧控件
    //
    drawArc: function (vCtrlArc) {
        // 检查控件类型
        if (vCtrlArc.type != HG.ControlType.arc) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlArc.foreColor;
        this.ctxBuf.lineWidth = vCtrlArc.lineWidth;
        // 绘制圆
        this.ctxBuf.beginPath();
        this.ctxBuf.arc(vCtrlArc.x + (vCtrlArc.w / 2),
            vCtrlArc.y + (vCtrlArc.h / 2),
            Math.min((vCtrlArc.w / 2), (vCtrlArc.h / 2)),
            vCtrlArc.startAg * Math.PI / 180, vCtrlArc.endAg * Math.PI / 180);
        // 绘制
        this.ctxBuf.stroke();
    },
    //-----------------------------------------------------------------------------
    // 绘制日期时间控件
    //
    drawTime: function (vCtrlTime) {
        let vTime = new Date();
        let vTimeDsp = '';
        // 检查日期时间控件类型

        if (vCtrlTime.type != HG.ControlType.time) return;

        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlTime.foreColor;
        this.ctxBuf.font = vCtrlTime.fontSize + 'pt ' + vCtrlTime.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';

        // 检查日期时间的类型
        switch (vCtrlTime.timeType) {
            default:
            case HG.AttrSelType.TimeStyleType.fullDateTime:
                // 日期时间类型
                vTimeDsp = vTime.getFullYear().toString() + '/';
                vTimeDsp += (vTime.getMonth() + 1).toString().padStart(2, "0") + '/';
                vTimeDsp += vTime.getDate().toString().padStart(2, "0") + ' ';
                vTimeDsp += vTime.getHours().toString().padStart(2, "0") + ':';
                vTimeDsp += vTime.getMinutes().toString().padStart(2, "0") + ':';
                vTimeDsp += vTime.getSeconds().toString().padStart(2, "0");
                break;
            case HG.AttrSelType.TimeStyleType.onlyDate:
                // 日期类型
                vTimeDsp = vTime.getFullYear().toString() + '/';
                vTimeDsp += (vTime.getMonth() + 1).toString().padStart(2, "0") + '/';
                vTimeDsp += vTime.getDate().toString().padStart(2, "0");
                break;
            case HG.AttrSelType.TimeStyleType.onlyTime:
                // 时间类型
                vTimeDsp = vTime.getHours().toString().padStart(2, "0") + ':';
                vTimeDsp += vTime.getMinutes().toString().padStart(2, "0") + ':';
                vTimeDsp += vTime.getSeconds().toString().padStart(2, "0");
                break;
        }
        // 绘制
        this.ctxBuf.fillText(vTimeDsp, vCtrlTime.x + (vCtrlTime.w / 2), vCtrlTime.y + (vCtrlTime.h / 2), vCtrlTime.w);
    },
    //-----------------------------------------------------------------------------
    // 绘制文字控件
    //
    drawText: function (vCtrlText) {
        // 检查控件类型
        if (vCtrlText.type != HG.ControlType.text) return;

        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlText.foreColor;
        this.ctxBuf.font = vCtrlText.fontSize + 'pt ' + vCtrlText.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';
        // 绘制
        this.ctxBuf.fillText(vCtrlText.text, (vCtrlText.x + (vCtrlText.w / 2)), (vCtrlText.y + (vCtrlText.h / 2)), vCtrlText.w);
    },
    //-----------------------------------------------------------------------------
    // 绘制图片
    //
    drawPicture: function (vCtrlPicture) {
        // 检查控件类型
        if (vCtrlPicture.type != HG.ControlType.pic) return;

        // 获取图片
        let vImg = this.getImage(vCtrlPicture.address);
        let vDOMObject = { obj: null, new: false };

        // 检查是否在编辑模式下
        if (this.editingMode) {
            // 绘图
            if (vCtrlPicture.address) {
                this.ctxBuf.drawImage(vImg, vCtrlPicture.x, vCtrlPicture.y, vCtrlPicture.w, vCtrlPicture.h);
            }

        } else {
            //
            // 检查是否为gif
            //
            if (vCtrlPicture.address.substr(vCtrlPicture.address.length - 4) == ".gif") {
                // 获取DOM控件
                HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlPicture.id, "img", vDOMObject);
                // 检查控件结构体中是否存在控件
                if (vDOMObject.obj) {
                    // 设置临时显示用DOM控件的位置
                    this.setDisplayDOMCtrlsPos({
                        x: vCtrlPicture.x,
                        y: vCtrlPicture.y,
                        w: vCtrlPicture.w,
                        h: vCtrlPicture.h
                    },
                        vDOMObject.obj);
                    // 检查是否为新控件
                    if (vDOMObject.new) {
                        // Url
                        vDOMObject.obj.src = vImg.src;
                    }
                }
            } else {
                // 绘图
                if (vCtrlPicture.address) {

                    this.ctxBuf.drawImage(vImg, vCtrlPicture.x, vCtrlPicture.y, vCtrlPicture.w, vCtrlPicture.h);
                }

            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制指示灯
    //
    drawLight: function (vCtrlLight) {
        // 检查控件类型
        if (vCtrlLight.type != HG.ControlType.light) return;

        // 图片
        let vImg = null;
        // 指示灯显示状态
        let vDspStatus = 0;

        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否有效
            if (vCtrlLight.dataValue) {
                // 获取数据值
                vDspStatus = vCtrlLight.dataValue;
            } else {
                // 设置为零
                vCtrlLight.dataValue = 0;
            }
        } else {
            // 指示灯显示状态
            vDspStatus = vCtrlLight.viewStatus;
        }

        // 检查指示灯的状态
        if (0 == vDspStatus) {
            // 获取图片off状态
            if (vCtrlLight.off) {
                vImg = this.getImage(vCtrlLight.off);
            }

        }
        else {
            // 获取图片on状态
            if (vCtrlLight.on) {
                vImg = this.getImage(vCtrlLight.on);
            }

        }
        // 绘制指示灯图片
        // this.ctxBuf.drawImage(vImg, vCtrlLight.x, vCtrlLight.y, vCtrlLight.w, vCtrlLight.h);
        let vDOMObject = { obj: null, new: false };

        // 检查是否在编辑模式下
        if (this.editingMode) {
            // 绘图
            if (vImg) {
                this.ctxBuf.drawImage(vImg, vCtrlLight.x, vCtrlLight.y, vCtrlLight.w, vCtrlLight.h);
            }
        } else {
            //
            // 检查是否为gif
            //
            if (vImg) {
                
                if (vImg.src.indexOf(".gif")!=-1) {
                    // 获取DOM控件
                    HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlLight.id, "img", vDOMObject);
                    // 检查控件结构体中是否存在控件
                    if (vDOMObject.obj) {
                        // 设置临时显示用DOM控件的位置
                        this.setDisplayDOMCtrlsPos({
                            x: vCtrlLight.x,
                            y: vCtrlLight.y,
                            w: vCtrlLight.w,
                            h: vCtrlLight.h
                        },
                            vDOMObject.obj);
                          
                        // 检查是否为新控件
                        if (vDOMObject.new) {
                            // Url
                            vDOMObject.obj.src = vImg.src;
                        } else if (vDOMObject.obj.src !== vImg.src) {
                            vDOMObject.obj.src = vImg.src;
                        }
                    }
                } else {
                    // 绘图
                    if (vImg) {
                        HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlLight.id, "img", vDOMObject);
                        if (vDOMObject.obj) {
                            vDOMObject.obj.style.display="none"
                        }
                        this.ctxBuf.drawImage(vImg, vCtrlLight.x, vCtrlLight.y, vCtrlLight.w, vCtrlLight.h);
                    }
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制位按钮
    //
    drawBBtn: function (vCtrlBBtn) {
        // 检查控件类型
        if (vCtrlBBtn.type != HG.ControlType.bbtn) return;

        // 图片
        let vImg = null;
        // 当前文字
        let vText = '';
        // 位按钮显示状态
        let vDspStatus = 0;

        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否有效
            if (vCtrlBBtn.dataValue) {
                // 获取数据值
                vDspStatus = Number(vCtrlBBtn.dataValue);
            } else {
                // 设置为零
                vCtrlBBtn.dataValue = 0;
            }
        } else {
            // 位按钮显示状态
            vDspStatus = vCtrlBBtn.viewStatus;
        }
        // 检查指示灯的状态
        if (0 == vDspStatus) {
            // 检查是否有效
            if (vCtrlBBtn.off) {
                // 获取图片off状态
                vImg = this.getImage(vCtrlBBtn.off);
            }
            // 当前文字
            vText = vCtrlBBtn.offText;
        }
        else {
            // 检查是否有效
            if (vCtrlBBtn.on) {
                // 获取图片on状态
                vImg = this.getImage(vCtrlBBtn.on);
            }
            // 当前文字
            vText = vCtrlBBtn.onText;
        }
        // 检查图片是否有效
        if (vImg) {
            // 绘制位按钮
            this.ctxBuf.drawImage(vImg, vCtrlBBtn.x, vCtrlBBtn.y, vCtrlBBtn.w, vCtrlBBtn.h);
        }
        //
        // 绘制位按钮文字
        //
        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlBBtn.foreColor;
        this.ctxBuf.font = vCtrlBBtn.fontSize + 'pt ' + vCtrlBBtn.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';
        // 绘制文字
        this.ctxBuf.fillText(vText, vCtrlBBtn.x + (vCtrlBBtn.w / 2), vCtrlBBtn.y + (vCtrlBBtn.h / 2), vCtrlBBtn.w);
    },
    //-----------------------------------------------------------------------------
    // 绘制字按钮
    //
    drawWBtn: function (vCtrlWBtn) {
        // 检查控件类型
        if (vCtrlWBtn.type != HG.ControlType.wbtn) return;

        // 图片
        let vImg = null;
        // 检查是否有效
        if (vCtrlWBtn.address) {
            // 获取图片
            vImg = this.getImage(vCtrlWBtn.address);
        }
        // 检查图片是否有效
        if (vImg) {
            // 绘制字按钮
            this.ctxBuf.drawImage(vImg, vCtrlWBtn.x, vCtrlWBtn.y, vCtrlWBtn.w, vCtrlWBtn.h);
        }
        //
        // 绘制位按钮文字
        //
        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlWBtn.foreColor;
        this.ctxBuf.font = vCtrlWBtn.fontSize + 'pt ' + vCtrlWBtn.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';
        // 绘制文字
        this.ctxBuf.fillText(vCtrlWBtn.text, vCtrlWBtn.x + (vCtrlWBtn.w / 2), vCtrlWBtn.y + (vCtrlWBtn.h / 2), vCtrlWBtn.w);
    },
    //-----------------------------------------------------------------------------
    // 绘制页面按钮
    //
    drawPBtn: function (vCtrlPBtn) {
        // 检查控件类型
        if (vCtrlPBtn.type != HG.ControlType.pbtn) return;
        // 图片
        let vImg = null;
        // 检查页面按钮图片地址
        if (vCtrlPBtn.address) {
            // 获取图片
            vImg = this.getImage(vCtrlPBtn.address);
        }
        // 检查图片是否有效
        if (vImg) {
            // 绘制字按钮
            this.ctxBuf.drawImage(vImg, vCtrlPBtn.x, vCtrlPBtn.y, vCtrlPBtn.w, vCtrlPBtn.h);
        }
        //
        // 绘制页面按钮文字
        //
        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlPBtn.foreColor;
        this.ctxBuf.font = vCtrlPBtn.fontSize + 'pt ' + vCtrlPBtn.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';
        // 绘制文字
        this.ctxBuf.fillText(vCtrlPBtn.text, vCtrlPBtn.x + (vCtrlPBtn.w / 2), vCtrlPBtn.y + (vCtrlPBtn.h / 2), vCtrlPBtn.w);
    },
    //-----------------------------------------------------------------------------
    // 绘制数值输入控件
    //
    drawNumber: function (vCtrlNum) {
        // 默认显示数值
        let vDisplay = '0';
        // 检查控件类型
        if (vCtrlNum.type != HG.ControlType.num) return;
        // if (vCtrlNum.decimalPoint==undefined) {
        //     vCtrlNum.decimalPoint=0;
        // }
        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否为有效数据, 0时也为默认值
            if (vCtrlNum.dataValue) {
                // 获取数据值
                // let a=1;

                // for (let i = 0; i < vCtrlNum.decimalPoint; i++) {
                //     a=a*10
                // }

                vDisplay = vCtrlNum.dataValue;


            } else {
                // 设置为零
                vCtrlNum.dataValue = 0;
            }
        } else {
            // 默认显示数值
            vDisplay = '123456';
        }

        // 颜色
        this.ctxBuf.strokeStyle = vCtrlNum.borderColor;
        this.ctxBuf.fillStyle = vCtrlNum.bgColor;
        this.ctxBuf.lineWidth = 1;
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlNum.x, vCtrlNum.y, vCtrlNum.w, vCtrlNum.h);
        // 检查边框是否显示
        if (vCtrlNum.border) {
            this.ctxBuf.strokeRect(vCtrlNum.x, vCtrlNum.y, vCtrlNum.w, vCtrlNum.h);
        }
        // 颜色字体

        this.ctxBuf.fillStyle = vCtrlNum.paramState == 2 ? "red" : vCtrlNum.foreColor;
        this.ctxBuf.font = vCtrlNum.fontSize + 'pt ' + vCtrlNum.fontName;
        this.ctxBuf.textAlign = 'center';
        this.ctxBuf.textBaseline = 'middle';
        // 绘制文字
        this.ctxBuf.fillText(vDisplay, vCtrlNum.x + (vCtrlNum.w / 2), vCtrlNum.y + (vCtrlNum.h / 2), vCtrlNum.w);
    },

    //-----------------------------------------------------------------------------
    // 绘制文数对应控件
    //
    drawTextList: function (vCtrlTextList) {
        // 默认显示数值
        let vDisplay = '警告！数据错误，超出有限范围。';
        let vColor = '#000';
        // 默认显示数值
        let vDataValue = 0;

        // 检查控件类型
        if (vCtrlTextList.type != HG.ControlType.textLst) return;

        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否为有效数据, 0时也为默认值
            if (vCtrlTextList.dataValue) {
                // 获取数据值
                vDataValue = parseInt(vCtrlTextList.dataValue);
            } else {
                // 设置为零
                vCtrlTextList.dataValue = 0;
            }
        } else {
            // 显示预览值
            vDataValue = vCtrlTextList.viewStatus;
        }
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlTextList.borderColor;
        this.ctxBuf.fillStyle = vCtrlTextList.bgColor;
        this.ctxBuf.lineWidth = 1;
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlTextList.x, vCtrlTextList.y, vCtrlTextList.w, vCtrlTextList.h);
        // 检查边框是否显示
        if (vCtrlTextList.border) {
            this.ctxBuf.strokeRect(vCtrlTextList.x, vCtrlTextList.y, vCtrlTextList.w, vCtrlTextList.h);
        }
        // 获取当前的字符与颜色
        if ((vDataValue < 0) || (vDataValue >= vCtrlTextList.listValue.length)) {
            // 错误的数据
            if (null != vCtrlTextList.listOther) {
                // 其他的值
                vDisplay = vCtrlTextList.listOther.text;
                vColor = vCtrlTextList.listOther.color;
            }
        }
        else {
            // 检查列表数据长度
            if (vCtrlTextList.listValue.length > 0) {
                // 检查是否有效
                if(vCtrlTextList.listValue[vDataValue].text) {
                    // 选择的值
                    vDisplay = vCtrlTextList.listValue[vDataValue].text;
                    vColor = vCtrlTextList.listValue[vDataValue].color;
                }
            }
        }
        // 颜色字体
        this.ctxBuf.fillStyle = vColor;
        this.ctxBuf.font = vCtrlTextList.fontSize + 'pt ' + vCtrlTextList.fontName;

        // 检查是否为只读模式
        if(vCtrlTextList.editable) {
            // 左对齐
            this.ctxBuf.textAlign       = 'left';
            this.ctxBuf.textBaseline    = 'middle';
            // 绘制文字
            this.ctxBuf.fillText(vDisplay, vCtrlTextList.x, vCtrlTextList.y + (vCtrlTextList.h / 2), vCtrlTextList.w);
        } else {
            this.ctxBuf.textAlign       = 'center';
            this.ctxBuf.textBaseline    = 'middle';
            // 绘制文字
            this.ctxBuf.fillText(vDisplay, vCtrlTextList.x + (vCtrlTextList.w / 2), 
                                           vCtrlTextList.y + (vCtrlTextList.h / 2), vCtrlTextList.w);
        }
    },    
    //-----------------------------------------------------------------------------
    // 绘制下拉列表显示区域
    //
    drawDropDownList: function (vPrj, vPageChanged) {
        // 下拉列表区域
        let vRectDDL            = { x: 0, y: 0, w: 0, h: 0 };
        let vRectDDLItem        = null;
        let vRectScrollBar      = null;
        // 下拉列表显示字符
        let vDisplayItem        = '';
        // 下拉列表偏移值
        let vDisplayOffside     = { cx: 6, cy: 3 };
        // 下拉列表滚动条宽度
        let vDisplayScrollW     = HG.$Set.allSettings.scrollSize.ddlScrollBarWidth;
        // 下拉列表三角形宽度
        let vDisplayTriangle    = { cx: 10, cy: 5 };
        let vDisplayTrianglePos = { x: 0, y: 0, w: 0, h: 0 };

        // 检查页面是否改变
        if (vPageChanged) {
            // 重置下拉列表属性
            vPrj.curDdlLinked.ctrl      = null;
            vPrj.curDdlLinked.x         = 0;
            vPrj.curDdlLinked.y         = 0;
            vPrj.curDdlLinked.w         = 0;
            vPrj.curDdlLinked.h         = 0;
            vPrj.curDdlLinked.start     = 0;
            vPrj.curDdlLinked.max       = 0;
            // 页面改变后直接返回
            return;
        }

        // 临时用下拉列表关联控件指针
        if (vPrj.curDdlLinked.ctrl == null) return;
        // 检查控件类型
        if (vPrj.curDdlLinked.ctrl.type != HG.ControlType.textLst) return;
        // 检查是否为编辑模式下
        if (vPrj.curDdlLinked.ctrl.editingMode) return;

        // 下拉列表总项目
        vPrj.curDdlLinked.max   = vPrj.curDdlLinked.ctrl.listValue.length;
        // 检查列表显示项目数量
        if (vPrj.curDdlLinked.max < 1) vPrj.curDdlLinked.max = 1;
        if (vPrj.curDdlLinked.max > 7) vPrj.curDdlLinked.max = 7;

        // 计算位置
        vRectDDL.x  = vPrj.curDdlLinked.ctrl.x - vDisplayOffside.cx;
        vRectDDL.w  = vPrj.curDdlLinked.ctrl.w + vDisplayOffside.cx;
        vRectDDL.y  = vPrj.curDdlLinked.ctrl.y + vPrj.curDdlLinked.ctrl.h + vDisplayOffside.cy;
        vRectDDL.h  = (vPrj.curDdlLinked.max * vPrj.curDdlLinked.ctrl.h);
        // 检查位置
        if (vRectDDL.y + vRectDDL.h > vPrj.setting.height) {
            // 位置重置
            vRectDDL.y = vPrj.setting.height - vRectDDL.h - vDisplayOffside.cy;
        }
        // 重置显示下拉列表区域
        vPrj.curDdlLinked.x     = vRectDDL.x;
        vPrj.curDdlLinked.y     = vRectDDL.y;
        vPrj.curDdlLinked.w     = vRectDDL.w;
        vPrj.curDdlLinked.h     = vRectDDL.h;

        // 颜色
        this.ctxBuf.strokeStyle     = '#333333';
        this.ctxBuf.fillStyle       = '#DDDDDD';
        this.ctxBuf.lineWidth       = 1;
        // 绘制背景色
        this.ctxBuf.fillRect(vRectDDL.x, vRectDDL.y, vRectDDL.w, vRectDDL.h);
        // 绘制边框
        this.ctxBuf.strokeRect(vRectDDL.x, vRectDDL.y, vRectDDL.w, vRectDDL.h);

        // 字体
        this.ctxBuf.font            = vPrj.curDdlLinked.ctrl.fontSize + 'pt ' + vPrj.curDdlLinked.ctrl.fontName;
        // 文字样式
        this.ctxBuf.textAlign       = 'left';
        this.ctxBuf.textBaseline    = 'middle';
        // 项目位置重置
        vRectDDLItem                = { x: vRectDDL.x, y: vRectDDL.y, w: vRectDDL.w, h: vPrj.curDdlLinked.ctrl.h };
        // 绘制数量检查
        if (vPrj.curDdlLinked.ctrl.listValue.length > 0) {         
            // 绘制项目文字
            for (let lIdx = 0; lIdx < vPrj.curDdlLinked.max; lIdx++) {
                // 真实的索引
                let lIdxReal = lIdx + vPrj.curDdlLinked.start;
                // 检查超出
                if (lIdxReal > (vPrj.curDdlLinked.ctrl.listValue.length - 1)) {
                    // 跳出
                    break;
                }
                // 检查索引
                if (lIdxReal > vPrj.curDdlLinked.start) {
                    // 分割线颜色
                    this.ctxBuf.strokeStyle = '#FFFFFF';
                    this.ctxBuf.lineWidth   = 1;
                    // 绘制直线
                    this.ctxBuf.beginPath();
                    // 绘制分割线
                    this.ctxBuf.moveTo(vRectDDLItem.x + vDisplayOffside.cx, vRectDDLItem.y);
                    this.ctxBuf.lineTo(vRectDDLItem.x + vRectDDLItem.w - vDisplayOffside.cx, vRectDDLItem.y);
                    // 绘制
                    this.ctxBuf.stroke();
                    this.ctxBuf.closePath();
                }

                // 选择的值字符与颜色
                vDisplayItem            = vPrj.curDdlLinked.ctrl.listValue[lIdxReal].text;
                // 颜色字体
                this.ctxBuf.fillStyle   = '#000000';
                // 绘制文字
                this.ctxBuf.fillText(vDisplayItem, 
                                     vRectDDLItem.x + vDisplayOffside.cx, 
                                     vRectDDLItem.y + (vRectDDLItem.h / 2), vRectDDLItem.w);
                // 计算位置
                vRectDDLItem.y          += vPrj.curDdlLinked.ctrl.h;
            }
        }

        // 检查是否超出最大值
        if (vPrj.curDdlLinked.ctrl.listValue.length > vPrj.curDdlLinked.max) { 
            // 滚动条位置
            vRectScrollBar          = { x: (vRectDDL.x + vRectDDL.w - vDisplayScrollW),
                                        y: vRectDDL.y,
                                        w: vDisplayScrollW, h: vRectDDL.h };
            // 颜色
            this.ctxBuf.strokeStyle = '#333333';
            this.ctxBuf.fillStyle   = '#DDDDDD';
            // 绘制背景色
            this.ctxBuf.fillRect(vRectScrollBar.x, vRectScrollBar.y, vRectScrollBar.w, vRectScrollBar.h);
            // 绘制边框
            this.ctxBuf.strokeRect(vRectScrollBar.x, vRectScrollBar.y, vRectScrollBar.w, vRectScrollBar.h);

            //
            // 绘制向上三角形
            //
            // 检查索引是否为0
            if (0 === vPrj.curDdlLinked.start) {
                this.ctxBuf.fillStyle   = '#999999';
            } else {
                this.ctxBuf.fillStyle   = '#333333';
            }
            // 绘制直线
            this.ctxBuf.beginPath();
            // 向上三角形位置
            vDisplayTrianglePos.x   = vRectScrollBar.x + ((vRectScrollBar.w - vDisplayTriangle.cx) / 2);
            vDisplayTrianglePos.y   = vRectScrollBar.y + (vDisplayTriangle.cy * 3);
            vDisplayTrianglePos.w   = vDisplayTriangle.cx;
            vDisplayTrianglePos.h   = vDisplayTriangle.cy;
            // 绘制向上三角形
            this.ctxBuf.moveTo(vDisplayTrianglePos.x, vDisplayTrianglePos.y);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x + vDisplayTrianglePos.w, vDisplayTrianglePos.y);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x + (vDisplayTrianglePos.w / 2), 
                               vDisplayTrianglePos.y - vDisplayTrianglePos.h);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x, vDisplayTrianglePos.y);
            // 绘制
            this.ctxBuf.fill();
            this.ctxBuf.closePath();

            //
            // 绘制向下三角形
            //
            // 检查索引是否为0
            if ((vPrj.curDdlLinked.start + vPrj.curDdlLinked.max) >= vPrj.curDdlLinked.ctrl.listValue.length - 1) {
                this.ctxBuf.fillStyle   = '#999999';
            } else {
                this.ctxBuf.fillStyle   = '#333333';
            }
            // 绘制直线
            this.ctxBuf.beginPath();
            // 向下三角形位置
            vDisplayTrianglePos.x   = vRectScrollBar.x + ((vRectScrollBar.w - vDisplayTriangle.cx) / 2);
            vDisplayTrianglePos.y   = vRectScrollBar.y + vRectScrollBar.h - (vDisplayTriangle.cy * 3);
            vDisplayTrianglePos.w   = vDisplayTriangle.cx;
            vDisplayTrianglePos.h   = vDisplayTriangle.cy;
            // 绘制向下三角形
            this.ctxBuf.moveTo(vDisplayTrianglePos.x, vDisplayTrianglePos.y);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x + vDisplayTrianglePos.w, vDisplayTrianglePos.y);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x + (vDisplayTrianglePos.w / 2), 
                               vDisplayTrianglePos.y + vDisplayTrianglePos.h);
            this.ctxBuf.lineTo(vDisplayTrianglePos.x, vDisplayTrianglePos.y);
            // 绘制
            this.ctxBuf.fill();
            this.ctxBuf.closePath();
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制曲线控件
    //
    drawCurves: function (vCtrlCurves) {
        // 默认显示数值
        let vIdx = 0;
        let vCurve = null;
        let vLabelW = 60;
        let vBlankW = 35;
        let vX = 0;
        let vY = 0;
        let vW = 0;
        let vDspLblW = 20;
        let vYMid = 0;
        let vDetailData = null;
        let vUpdateDate = 0;
        // 检查控件类型
        if (vCtrlCurves.type != HG.ControlType.curves) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlCurves.foreColor;
        this.ctxBuf.fillStyle = vCtrlCurves.bgColor;
        this.ctxBuf.lineWidth = 1;
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlCurves.x, vCtrlCurves.y, vCtrlCurves.w, vCtrlCurves.h);

        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlCurves.foreColor;
        this.ctxBuf.font = vCtrlCurves.fontSize + 'pt ' + vCtrlCurves.fontName;
        // 绘制坐标
        this.drawCoordinates(this.ctxBuf,
            {
                l: vCtrlCurves.x, r: vCtrlCurves.x + vCtrlCurves.w,
                t: vCtrlCurves.y, b: vCtrlCurves.y + vCtrlCurves.h
            },
            {
                x: parseInt(vCtrlCurves.unitX),
                maxY: parseInt(vCtrlCurves.maxY),
                minY: parseInt(vCtrlCurves.minY),
                y: parseInt(vCtrlCurves.unitY),
            },
            { label: vLabelW, blank: vBlankW },
            { coordinate: vCtrlCurves.foreColor, unit: vCtrlCurves.unitColor },
        );

        // 绘制文字位置
        this.ctxBuf.textBaseline = 'middle';
        this.ctxBuf.textAlign = 'left';
        // 绘制位置
        vX = vCtrlCurves.x + vLabelW + vBlankW;
        vY = vCtrlCurves.y + (vBlankW - vDspLblW) / 2;
        vYMid = vCtrlCurves.y + vBlankW / 2;

        // 更新时间
        vUpdateDate = (new Date()).getTime();

        // 绘制曲线
        for (vIdx = 0; vIdx < vCtrlCurves.curvesData.length; vIdx++) {
            // 获取曲线数据
            vCurve = vCtrlCurves.curvesData[vIdx];

            // 是否显示标签
            if (vCtrlCurves.lblVisible) {
                // 绘制颜色
                this.ctxBuf.fillStyle = vCurve.color;
                // 绘制标签颜色
                this.ctxBuf.fillRect(vX, vY, vDspLblW, vDspLblW);
                // 位置偏移
                vX += vDspLblW;
                // 绘制文字
                this.ctxBuf.fillText(vCurve.text, vX, vYMid);
                // 测量文字
                vW = this.ctxBuf.measureText(vCurve.text);
            }

            // 编辑模式下绘制示例曲线
            if (this.editingMode) {
                // 绘制示例曲线
                this.drawSampleCurve(this.ctxBuf,
                    vCurve,
                    vCtrlCurves.x + vLabelW,
                    vCtrlCurves.y + vBlankW,
                    vCtrlCurves.x + vCtrlCurves.w - vBlankW,
                    vCtrlCurves.y + vCtrlCurves.h - vBlankW,
                    vCurve.color);
            } else {
                // 页面改变时，清空实时数据
                if (this.drawPrjPageChanged) {
                    // 执行模式下获取数据
                    if (vCurve.$currentData) {
                        // 删除
                        delete vCurve.$currentData;
                        vCurve.$currentData = null;
                    }
                }
                // 执行模式下获取数据
                if (!vCurve.$currentData) {
                    // 获取
                    vCurve.$currentData = {
                        // 最后一次的数据
                        lastDate: 0,
                        // 数据列表
                        dataArray: [],
                    };
                }
                // 获取历史数据
                vDetailData = vCurve.$currentData;
                // 检查是否需要刷新数据
                if (vUpdateDate - vDetailData.lastDate > 1000) {
                    // 更新当前数据
                    vDetailData.dataArray.push(HMIDataPool.loadCOMMData(vCurve.param));
                    // 更新时间
                    vDetailData.lastDate = vUpdateDate;
                }
                // 绘制实时曲线
                this.drawCurrentCurve(this.ctxBuf,
                    vDetailData,
                    vCtrlCurves.x + vLabelW,
                    vCtrlCurves.y + vBlankW,
                    vCtrlCurves.x + vCtrlCurves.w - vBlankW,
                    vCtrlCurves.y + vCtrlCurves.h - vBlankW,
                    vCurve);
            }

            // 位置偏移
            vX += vW.width + vBlankW;
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制坐标轴
    //
    drawCoordinates: function (ctx, rect, scale, margin, color) {
        // 绘制坐标
        let vXSt = rect.l + margin.label;
        let vXEd = rect.r - margin.blank;
        let vYSt = rect.b - margin.blank;
        let vYEd = rect.t + margin.blank;
        let vXCur = 0;
        let vXScale = 0;
        let vYFull = false;

        // 保存
        ctx.save();
        // 绘制线
        ctx.beginPath();

        // 坐标轴颜色
        ctx.strokeStyle = color.coordinate;
        // 绘制X坐标
        this.drawCoordinateArrow(ctx, vXSt, vYSt, rect.r, vYSt, false);
        // 绘制Y坐标
        this.drawCoordinateArrow(ctx, vXSt, vYSt, vXSt, rect.t, true);
        // 绘制
        ctx.stroke();
        ctx.closePath();

        // 绘制线
        ctx.beginPath();
        // 绘制文字位置
        ctx.textBaseline = 'top';
        ctx.textAlign = 'left';
        // 绘制刻度
        ctx.fillText(0, vXSt, vYSt, margin.label);
        // 绘制文字位置
        ctx.textAlign = 'center';
        // 坐标线颜色
        ctx.strokeStyle = color.unit;
        // X, y
        vXCur = vXSt + scale.x;
        // X刻度
        vXScale = scale.x;
        // 绘制X刻度
        for (vXCur = vXSt + scale.x; vXCur <= vXEd;) {
            // 绘制线段
            ctx.moveTo(vXCur, vYSt);
            ctx.lineTo(vXCur, vYEd);
            // 绘制刻度
            ctx.fillText(vXScale, vXCur, vYSt, margin.label);
            // X
            vXCur += scale.x;
            // X刻度
            vXScale += scale.x;
        }

        // 绘制Y刻度线
        vYFull = this.drawYScaleLine(ctx, vXSt, vYSt, vXEd, vYEd, scale, margin.label);
        // 绘制
        ctx.stroke();
        ctx.closePath();

        // 绘制线
        ctx.beginPath();
        // 颜色
        ctx.strokeStyle = color.unit;
        ctx.lineWidth = 1;
        ctx.setLineDash([10, 8]);
        // 检查当前
        if (vXCur != vXEd) {
            // 绘制线段
            ctx.moveTo(vXEd, vYSt);
            ctx.lineTo(vXEd, vYEd);
        }
        // 检查Y刻度线是否绘制结束
        if (!vYFull) {
            // 绘制线段
            ctx.moveTo(vXSt, vYEd);
            ctx.lineTo(vXEd, vYEd);
        }
        // 绘制
        ctx.stroke();
        ctx.closePath();
        // 恢复
        ctx.restore();
    },
    //-----------------------------------------------------------------------------
    // 绘制坐标轴
    //
    drawCoordinateArrow: function (ctx, stX, stY, edX, edY, reverse) {
        let vArrowL = 12, vArrowT = 4;

        // 绘制坐标
        ctx.moveTo(stX, stY);
        ctx.lineTo(edX, edY);
        // 检查X轴还是Y轴
        if (stY === edY) {
            // 检查是否为反向
            if (!reverse) {
                ctx.lineTo(edX - vArrowL, edY - vArrowT);
                ctx.moveTo(edX, edY);
                ctx.lineTo(edX - vArrowL, edY + vArrowT);
            }
            else {
                ctx.lineTo(edX + vArrowL, edY - vArrowT);
                ctx.moveTo(edX, edY);
                ctx.lineTo(edX + vArrowL, edY + vArrowT);
            }
        }
        else {
            // 检查是否为反向
            if (!reverse) {
                ctx.lineTo(edX - vArrowT, edY - vArrowL);
                ctx.moveTo(edX, edY);
                ctx.lineTo(edX + vArrowT, edY - vArrowL);
            }
            else {
                ctx.lineTo(edX - vArrowT, edY + vArrowL);
                ctx.moveTo(edX, edY);
                ctx.lineTo(edX + vArrowT, edY + vArrowL);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制Y刻度线
    //
    drawYScaleLine: function (ctx, stX, stY, edX, edY, scale, label) {
        let vY = scale.minY;
        let vLblSt = stX - (label / 2);
        let vValue = 0;
        let vRate = (edY - stY) / (scale.maxY - scale.minY);

        // 是否小于零
        if (vY < 0) {
            // 计算
            vValue = (-vY) % scale.y;
            // 检查是否为零
            if (vValue > 0) {
                // 累计
                vY += vValue;
            }
            else {
                // 下一刻度
                vY += scale.y;
            }
        }
        else {
            // 计算
            vValue = vY % scale.y;
            // 检查是否为零
            if (vValue > 0) {
                // 累计
                vY += (scale.y - vValue);
            }
            else {
                // 下一刻度
                vY += scale.y;
            }
        }

        // 绘制文字位置
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        // 绘制刻度
        ctx.fillText(scale.minY, vLblSt, stY, label);
        // 循环检查
        while (vY < scale.maxY) {
            // 设置Y轴位置
            vValue = (vY - scale.minY) * vRate + stY;
            // 绘制线段
            ctx.moveTo(stX, vValue);
            ctx.lineTo(edX, vValue);
            // 绘制刻度
            ctx.fillText(vY, vLblSt, vValue, label);
            // 下一刻度
            vY += scale.y;
        }
        // 最后绘制刻度
        ctx.fillText(scale.maxY, vLblSt, edY, label);
        // 检查最后一条线
        if (vY === scale.maxY) {
            // 绘制线段
            ctx.moveTo(stX, edY);
            ctx.lineTo(edX, edY);
            // 绘制满区域
            return true;
        }

        // 未绘制满区域
        return false;
    },
    //-----------------------------------------------------------------------------
    // 绘制示例曲线
    //
    drawSampleCurve: function (ctx, vCurve, stX, stY, edX, edY, color) {
        let vCnt = 6;
        let vW = edX - stX;
        let vH = edY - stY;
        let vWPart = (vW / (vCnt - 1));
        let vIdx = 0;
        let vAllData = null;

        // 编辑模式下的数据
        if (!vCurve.$editData) {
            // 设置
            vCurve.$editData = { vY: [] };
            // 循环设置
            for (vIdx = 0; vIdx < vCnt; vIdx++) {
                vCurve.$editData.vY.push(Math.round(Math.random() * 100) / 100);
            }
        }
        // 获取数据
        vAllData = vCurve.$editData.vY;

        // 部分
        vWPart = (vW / (vCnt - 1));
        // 保存
        ctx.save();
        // 颜色
        ctx.strokeStyle = color;
        ctx.lineWidth = 1;
        // 绘制线
        ctx.beginPath();
        // 循环设置
        for (vIdx = 0; vIdx < vAllData.length; vIdx++) {
            // 检查是否为第一个
            if (0 === vIdx) {
                // 开始线段
                ctx.moveTo(vIdx * vWPart + stX, vAllData[vIdx] * vH + stY);
            }
            else {
                // 绘制线段
                ctx.lineTo(vIdx * vWPart + stX, vAllData[vIdx] * vH + stY);
                ctx.lineCap = "round"
            }
        }
        // 绘制
        ctx.stroke();
        ctx.closePath();
        // 恢复
        ctx.restore();
    },
    //-----------------------------------------------------------------------------
    // 绘制实时曲线
    //
    drawCurrentCurve: function (ctx, vCurveData, stX, stY, edX, edY, vCurve) {
        let vW = edX - stX;
        let vH = edY - stY;
        let vWPart = 1;
        let vIdx = 0;
        let vAllData = null;
        let vItem = 0;
        let vMin = vCurve.min;
        let vMax = vCurve.max;
        let color = vCurve.color;
        // 数据数组是否存在
        if (vCurveData.dataArray.length == 0) {
            // 不存在时直接返回
            return;
        }
        // 检查长度
        if (vCurveData.dataArray.length >= vW) {
            // 移除第一个项目
            vCurveData.dataArray.splice(0, 1);
        }
        // 获取数据
        vAllData = vCurveData.dataArray;

        // 保存
        ctx.save();
        // 颜色
        ctx.strokeStyle = color;
        ctx.lineWidth = 1;
        // 绘制线
        ctx.beginPath();
        // 循环设置
        for (vIdx = 0; vIdx < vAllData.length; vIdx++) {
            // 检查位置
            if (stX >= edX) {
                break;
            }
            // 获取当前值
            vItem = vAllData[vIdx];
            // 转换成比率
            if (vItem < vMin) vItem = 0;
            else if (vItem >= vMax) vItem = vH;
            else {
                // 计算比率
                vItem -= vMin;
                // 占比
                vItem = vItem * vH / (vMax - vMin);
                // 检查是否超出有效范围
                if (vItem < 0) vItem = 0;
                if (vItem > vH) vItem = vH;
            }
            // 检查是否为第一个
            if (0 === vIdx) {
                // 开始线段
                ctx.moveTo(stX, vH - vItem + stY);
            }
            else {
                // 绘制线段
                ctx.lineTo(stX, vH - vItem + stY);
            }
            // 开始位置递增
            stX += vWPart;
        }
        // 绘制
        ctx.lineCap = "round"
        ctx.stroke();
        ctx.closePath();
        // 恢复
        ctx.restore();
    },
    //-----------------------------------------------------------------------------
    // 绘制柱状图控件
    //
    drawColumns: function (vCtrlColumn) {
        // 默认显示数值
        let vIdx = 0;
        let vColumn = null;
        let vLabelW = 60;
        let vBlankW = 35;
        let vUnitW = 0;
        let vColW = 0;
        let vColumnLbl = '';
        let vColumnData = 0;

        // 检查控件类型
        if (vCtrlColumn.type != HG.ControlType.columns) return;
        // 颜色
        this.ctxBuf.strokeStyle = vCtrlColumn.foreColor;
        this.ctxBuf.fillStyle = vCtrlColumn.bgColor;
        this.ctxBuf.lineWidth = 1;
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlColumn.x, vCtrlColumn.y, vCtrlColumn.w, vCtrlColumn.h);
        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlColumn.foreColor;
        this.ctxBuf.font = vCtrlColumn.fontSize + 'pt ' + vCtrlColumn.fontName;
        // 绘制坐标
        this.drawColumnCoordinates(this.ctxBuf,
            {
                l: vCtrlColumn.x, r: vCtrlColumn.x + vCtrlColumn.w,
                t: vCtrlColumn.y, b: vCtrlColumn.y + vCtrlColumn.h
            },
            {
                xCnt: vCtrlColumn.columnsData.length,
                maxY: parseInt(vCtrlColumn.maxY),
                minY: parseInt(vCtrlColumn.minY),
                y: parseInt(vCtrlColumn.unitY),
            },
            { label: vLabelW, blank: vBlankW },
            { coordinate: vCtrlColumn.foreColor, unit: vCtrlColumn.unitColor },
        );

        // 绘制文字位置
        this.ctxBuf.textBaseline = 'middle';
        this.ctxBuf.textAlign = 'left';

        // 检查柱状图的柱数据
        if (vCtrlColumn.columnsData.length > 0) {
            // 获取有效宽度、高度
            vUnitW = (vCtrlColumn.w - vLabelW - vBlankW) / vCtrlColumn.columnsData.length;
            vColW = vUnitW / 2;
        }

        // 绘制柱状图
        for (vIdx = 0; vIdx < vCtrlColumn.columnsData.length; vIdx++) {
            // 获取柱状数据
            vColumn = vCtrlColumn.columnsData[vIdx];
            // 数据标签
            vColumnLbl = vColumn.text;
            // 检查是否显示标签
            if (!vCtrlColumn.lblVisible) {
                // 数据标签
                vColumnLbl = '';
            }

            // 柱状数据
            vColumnData = 0;
            // 编辑模式下绘制示例曲线
            if (this.editingMode) {
                // 检查
                if (!vColumn.$editData) {
                    // 设置
                    vColumn.$editData = Math.round(Math.random() * 100) * vColumn.max / 100;
                }
                // 柱状数据
                vColumnData = vColumn.$editData;
            } else {
                // 检查是否存在
                if (vColumn.dataValue) {
                    // 获取数据
                    vColumnData = vColumn.dataValue;
                }
            }
            // 绘制柱图
            this.drawColumnDetail(this.ctxBuf,
                {
                    l: vCtrlColumn.x + vLabelW + vIdx * vUnitW,
                    t: vCtrlColumn.y + vBlankW,
                    w: vUnitW,
                    h: vCtrlColumn.h - vBlankW - vBlankW
                },
                vColW, (vBlankW / 2), vColumnLbl,
                vColumnData, vColumn.max,
                vColumn.color, vCtrlColumn.bgColor);
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制柱状坐标轴
    //
    drawColumnCoordinates: function (ctx, rect, scale, margin, color) {
        // 绘制坐标
        let vXSt = rect.l + margin.label;
        let vXEd = rect.r - margin.blank;
        let vYSt = rect.b - margin.blank;
        let vYStU = vYSt + 8;
        let vYEd = rect.t + margin.blank;
        let vIdx = 0;
        let vXCur = 0;
        let vXScale = 0;
        let vYFull = false;

        // 保存
        ctx.save();
        // 绘制线
        ctx.beginPath();

        // 坐标轴颜色
        ctx.strokeStyle = color.coordinate;
        // 绘制X坐标
        ctx.moveTo(vXSt, vYSt);
        ctx.lineTo(vXEd, vYSt);
        // 绘制Y坐标
        ctx.moveTo(vXSt, vYSt);
        ctx.lineTo(vXSt, vYEd);
        // 绘制
        ctx.stroke();
        ctx.closePath();

        // 检查x刻度数量
        if (scale.xCnt > 0) {
            // 绘制线
            ctx.beginPath();
            // 坐标线颜色
            ctx.strokeStyle = color.coordinate;
            // X刻度
            vXScale = (vXEd - vXSt) / scale.xCnt;
            // 绘制X刻度
            for (vIdx = 0; vIdx < scale.xCnt; vIdx++) {
                // X, y
                vXCur = vXSt + (vIdx + 1) * vXScale;
                // 绘制线段
                ctx.moveTo(vXCur, vYSt);
                ctx.lineTo(vXCur, vYStU);
            }
            // 绘制
            ctx.stroke();
            ctx.closePath();
        }

        // 绘制线
        ctx.beginPath();
        // 坐标线颜色
        ctx.strokeStyle = color.unit;
        // 绘制Y刻度线
        vYFull = this.drawYScaleLine(ctx, vXSt, vYSt, vXEd, vYEd, scale, margin.label);
        // 绘制
        ctx.stroke();
        ctx.closePath();

        // 绘制线
        ctx.beginPath();
        // 颜色
        ctx.strokeStyle = color.unit;
        ctx.lineWidth = 1;
        ctx.setLineDash([10, 8]);
        // 检查当前
        if (vXCur != vXEd) {
            // 绘制线段
            ctx.moveTo(vXEd, vYSt);
            ctx.lineTo(vXEd, vYEd);
        }
        // 检查Y刻度线是否绘制结束
        if (!vYFull) {
            // 绘制线段
            ctx.moveTo(vXSt, vYEd);
            ctx.lineTo(vXEd, vYEd);
        }
        // 绘制
        ctx.stroke();
        ctx.closePath();
        // 恢复
        ctx.restore();
    },
    //-----------------------------------------------------------------------------
    // 绘制柱状图的柱形
    //
    drawColumnDetail: function (ctx, rect, colW, lblH, label, data, max, color, bgColor) {
        // 绘制坐标
        let vXSt = rect.l + (rect.w - colW) / 2;
        let vYEd = rect.t + rect.h;
        let vYSt = 0;
        let vRate = 0;

        // 检查是否有效
        if (data) {
            // 转换为数值
            data = parseFloat(data);
        } else {
            // 清空
            data = 0;
        }

        // 检查最大值是否有效
        if (max > 0) {
            // 计算比例
            vRate = (data / max);
            // 检查比率的最小值、最大值
            if (vRate < 0) vRate = 0;
            if (vRate > 1) vRate = 1;
            // Y Start
            vYSt = vYEd - rect.h * vRate;

            // 保存
            ctx.save();
            // 绘制颜色
            ctx.fillStyle = color;
            // 绘制柱图
            ctx.fillRect(vXSt, vYSt, colW, vYEd - vYSt);
            // 居中设置
            ctx.textAlign = 'center';
            // 检查标签是否为空
            if (label != '') {
                // 绘制标签
                ctx.fillText(label, rect.l + (rect.w / 2), rect.t + rect.h + lblH, rect.w);
            }

            // 绘制文字
            if (vRate > 0.5) {
                // 绘制颜色
                ctx.fillStyle = bgColor;
                // 绘制文字
                ctx.fillText(data.toFixed(2), vXSt + (colW / 2), vYSt + lblH, colW);
            } else {
                // 绘制颜色
                ctx.fillStyle = color;
                // 绘制文字
                ctx.fillText(data.toFixed(2), vXSt + (colW / 2), vYSt - lblH, colW);
            }
            // 恢复
            ctx.restore();
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制饼图控件
    //
    drawPies: function (vCtrlPies) {
        // 默认显示数值
        let vIdx = 0;
        let vPies = null;
        let vMargin = 3;
        let vBlankW = 28;
        let vR = Math.min(((vCtrlPies.w / 2) - vBlankW), ((vCtrlPies.h / 2) - vBlankW));
        let vCX = vCtrlPies.x + vCtrlPies.w - vR - vBlankW;
        let vCY = vCtrlPies.y + (vCtrlPies.h / 2);
        let vstartAg = 0;
        let vendAg = 0;
        let vTotal = 0;
        let vLblX = 0;
        let vLblY = 0;
        let vDspLblW = 20;
        let vYMid = 0;
        let vRate = 0;
        let vData = 0;
        let vPieData = 0;

        // 检查控件类型
        if (vCtrlPies.type != HG.ControlType.pies) return;

        // 颜色字体
        this.ctxBuf.fillStyle = vCtrlPies.foreColor;
        this.ctxBuf.font = vCtrlPies.fontSize + 'pt ' + vCtrlPies.fontName;
        this.ctxBuf.textBaseline = 'middle';
        this.ctxBuf.textAlign = 'left';

        // 绘制颜色
        this.ctxBuf.fillStyle = vCtrlPies.bgColor;
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlPies.x, vCtrlPies.y, vCtrlPies.w, vCtrlPies.h);
        // 绘制饼图
        for (vIdx = 0; vIdx < vCtrlPies.piesData.length; vIdx++) {
            // 获取饼图数据
            vPies = vCtrlPies.piesData[vIdx];

            // 饼图数据
            vPieData = 0;
            // 编辑模式下的临时数据
            if (this.editingMode) {
                // 检查默认数据
                if (!vPies.$editData) {
                    // 随机值
                    vPies.$editData = Math.round(Math.random() * 100);
                }
                // 饼图数据
                vPieData = vPies.$editData;
            } else {
                // 检查是否存在
                if (vPies.dataValue) {
                    // 获取数据
                    vPieData = parseFloat(vPies.dataValue);
                    // 检查是否超出
                    if (vPieData < vPies.min) vPieData = vPies.min;
                    if (vPieData > vPies.max) vPieData = vPies.max;
                }
            }
            // 计算总计
            vTotal += vPieData;
        }

        // 显示标签
        vLblX = vCtrlPies.x + vMargin;
        vLblY = vCtrlPies.y + vMargin + (vBlankW - vDspLblW) / 2;
        vYMid = vCtrlPies.y + (vBlankW / 2) + vMargin;

        // 绘制饼图
        for (vIdx = 0; vIdx < vCtrlPies.piesData.length; vIdx++) {
            // 获取饼图数据
            vPies = vCtrlPies.piesData[vIdx];

            // 饼图数据
            vPieData = 0;
            // 编辑模式下的临时数据
            if (this.editingMode) {
                // 饼图数据
                vPieData = vPies.$editData;
            } else {
                // 检查是否存在
                if (vPies.dataValue) {
                    // 获取数据
                    vPieData = parseFloat(vPies.dataValue);
                    // 检查是否超出
                    if (vPieData < vPies.min) vPieData = vPies.min;
                    if (vPieData > vPies.max) vPieData = vPies.max;
                }
            }

            // 当前的值
            vRate = 0;
            // 当前的值
            vData = 0;

            // 绘制颜色
            this.ctxBuf.fillStyle = vPies.color;

            // 检查总计
            if (vTotal > 0) {
                // 结束角度
                vendAg += vPieData * Math.round(Math.PI * 1000) * 2 / (1000 * vTotal);
                // 当前的值
                vRate = (vPieData * 100 / vTotal).toFixed(0);
                // 当前的值
                vData = vPieData.toFixed(0);

                // 开始路径
                this.ctxBuf.beginPath();
                // 绘制饼图
                this.ctxBuf.moveTo(vCX, vCY);
                // 绘制饼图
                this.ctxBuf.arc(vCX, vCY, vR, vstartAg, vendAg, false);
                // 路径结束
                this.ctxBuf.closePath();
                // 绘制
                this.ctxBuf.fill();
                // 开始角度
                vstartAg = vendAg;
            }

            // 是否显示标签
            if (vCtrlPies.lblVisible) {
                //
                // 显示标签
                //
                // 绘制标签颜色
                this.ctxBuf.fillRect(vLblX, vLblY, vDspLblW, vDspLblW);
                // 位置偏移
                vLblX += vDspLblW;
                // 绘制文字
                this.ctxBuf.fillText(vPies.text + '[' + vRate + '% ' + vData + ']', vLblX, vYMid);
                // 显示标签 位置偏移
                vLblX = vCtrlPies.x + vMargin;
                vLblY += vMargin + vBlankW;
                vYMid += vMargin + vBlankW;
            }
        }

        // 无数据时
        if (vCtrlPies.piesData.length === 0) {
            // 颜色字体
            this.ctxBuf.fillStyle = '#666';
            this.ctxBuf.textAlign = 'center';
            // 显示标签 位置偏移
            vLblX = vCtrlPies.x + vCtrlPies.w / 2;
            vLblY = vCtrlPies.y + vCtrlPies.h / 2;
            // 绘制无数据
            this.ctxBuf.fillText('无数据', vLblX, vLblY);
        }
    },
    //-----------------------------------------------------------------------------
    // 报警分页
    //
    drawWarningTable: function (vCtrlWarningTable) {
        let vDOMObject = { obj: null, new: false };
        let rect = {
            x: vCtrlWarningTable.x,
            y: vCtrlWarningTable.y,
            w: vCtrlWarningTable.w,
            h: vCtrlWarningTable.h
        };

        // 检查控件类型
        if (vCtrlWarningTable.type != HG.ControlType.warningTable) return;
        // 检查是否为背景透明
        if (vCtrlWarningTable.transparent) {
            // 绘制颜色
            this.ctxBuf.fillStyle = '#FFF0';
        } else {
            // 绘制颜色
            this.ctxBuf.fillStyle = vCtrlWarningTable.bgColor;
        }
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlWarningTable.x, vCtrlWarningTable.y, vCtrlWarningTable.w, vCtrlWarningTable.h);
        // 获取DOM控件
        HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlWarningTable.id, "div", vDOMObject);

        // 检查外部控件的是否存在
        if (vDOMObject.obj) {
            // 设置临时显示用DOM控件的位置
            this.setDisplayDOMCtrlsPos(rect, vDOMObject.obj);
            // 外部控件注册 (历史曲线控件注册)
            HMIExterCtrls.register(HG.ControlType.warningTable, vDOMObject.obj, this.editingMode, vCtrlWarningTable, vDOMObject.new);
            // 检查是否为新控件，需要初始化
            if (vDOMObject.new) {
                // 设置透明背景色
                vDOMObject.obj.style.backgroundColor = '#FFF0';
            } else {
                // 改变尺寸
                HMIExterCtrls.resize(HG.ControlType.warningTable, vDOMObject.obj, rect.w, rect.h);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制历史曲线
    //
    drawHistoryCurves: function (vCtrlHistoryCurves) {
        let vDOMObject = { obj: null, new: false };
        let rect = {
            x: vCtrlHistoryCurves.x,
            y: vCtrlHistoryCurves.y,
            w: vCtrlHistoryCurves.w,
            h: vCtrlHistoryCurves.h
        };
        // 检查控件类型
        if (vCtrlHistoryCurves.type != HG.ControlType.historyCurves) return;

        // 检查是否为背景透明
        if (vCtrlHistoryCurves.transparent) {
            // 绘制颜色
            this.ctxBuf.fillStyle = '#FFF0';
        } else {
            // 绘制颜色
            this.ctxBuf.fillStyle = vCtrlHistoryCurves.bgColor;
        }
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlHistoryCurves.x, vCtrlHistoryCurves.y, vCtrlHistoryCurves.w, vCtrlHistoryCurves.h);

        // 获取DOM控件
        HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlHistoryCurves.id, "div", vDOMObject);
        // 检查外部控件的是否存在
        if (vDOMObject.obj) {
            // 设置临时显示用DOM控件的位置
            this.setDisplayDOMCtrlsPos(rect, vDOMObject.obj);
            // 检查是否为新控件，需要初始化
            if (vDOMObject.new) {
                // 外部控件注册 (历史曲线控件注册)
                // console.log(vDOMObject);
                HMIExterCtrls.register(HG.ControlType.historyCurves, vDOMObject.obj, this.editingMode, vCtrlHistoryCurves);
                // 外部控件设置
                HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'testData', null);
                // 设置透明背景色
                vDOMObject.obj.style.backgroundColor = '#FFF0';
            } else {
                // 改变尺寸
                HMIExterCtrls.resize(HG.ControlType.historyCurves, vDOMObject.obj, rect.w, rect.h);
                // 外部控件设置
                HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'title', "");
                // HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'title', vCtrlHistoryCurves.title);
                // 外部控件字体
                HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'fontName', vCtrlHistoryCurves.fontName);
                HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'fontSize', vCtrlHistoryCurves.fontSize);

                // 检查是否在执行模式下
                if (!this.editingMode) {
                    // 当数据改变时，设置控件数据
                    HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'data', HMIDataPool.allData.historyData, vCtrlHistoryCurves);
                }
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制温度表盘输入控件
    //
    drawClockDial: function (vCtrlNum) {
        let vDOMObject = { obj: null, new: false };
        let rect = {
            x: vCtrlNum.x,
            y: vCtrlNum.y,
            w: vCtrlNum.w,
            h: vCtrlNum.h
        };
        // 检查控件类型
        if (vCtrlNum.type != HG.ControlType.clockDial) return;

        // 检查是否为背景透明
        if (vCtrlNum.transparent) {
            // 绘制颜色
            this.ctxBuf.fillStyle = '#FFF0';
        } else {
            // 绘制颜色
            this.ctxBuf.fillStyle = vCtrlNum.bgColor;
        }
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlNum.x, vCtrlNum.y, vCtrlNum.w, vCtrlNum.h);

        // 获取DOM控件
        HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlNum.id, "div", vDOMObject);
        // 检查外部控件的是否存在
        if (vDOMObject.obj) {
            // 设置临时显示用DOM控件的位置
            this.setDisplayDOMCtrlsPos(rect, vDOMObject.obj);
            // 检查是否为新控件，需要初始化
            if (vDOMObject.new) {
                // 外部控件注册 (温度仪表控件注册)
                HMIExterCtrls.register(HG.ControlType.clockDial, vDOMObject.obj, this.editingMode, vCtrlNum);
                // 外部控件设置
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'testData', null);
                // 设置透明背景色
                vDOMObject.obj.style.backgroundColor = '#FFF0';
            } else {
                // 改变尺寸
                HMIExterCtrls.resize(HG.ControlType.clockDial, vDOMObject.obj, rect.w, rect.h);
                // 外部控件设置
                // HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'title', "");
                // HMIExterCtrls.setParam(HG.ControlType.historyCurves, vDOMObject.obj, 'title', vCtrlHistoryCurves.title);
                // 外部控件字体
                // vDOMObject.obj.option.series.data[0].value = vCtrlNum.dataValue;

                // vDOMObject.obj.exterCtrl.setOption(vDOMObject.obj.option);
           
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'fontName', vCtrlNum.fontName);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'fontSize', vCtrlNum.fontSize);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'min', vCtrlNum.min);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'max', vCtrlNum.max);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'color', vCtrlNum.colors);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'scaleSize', vCtrlNum.scaleSize);
                HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'symbol', vCtrlNum.symbol);

                // 检查是否在执行模式下
                if (!this.editingMode) {
                    // 当数据改变时，设置控件数据

                    HMIExterCtrls.setParam(HG.ControlType.clockDial, vDOMObject.obj, 'data', vCtrlNum.dataValue, vCtrlNum.id);
                }
            }
        }
    },
    //数据表格
    drawDataTable: function (vCtrlNum) {
        let vDOMObject = { obj: null, new: false };
        let rect = {
            x: vCtrlNum.x,
            y: vCtrlNum.y,
            w: vCtrlNum.w,
            h: vCtrlNum.h
        };
        // 检查控件类型

        if (vCtrlNum.type != HG.ControlType.dataTable) return;


        // 检查是否为背景透明
        if (vCtrlNum.transparent) {
            // 绘制颜色
            this.ctxBuf.fillStyle = '#FFF0';
        } else {
            // 绘制颜色
            this.ctxBuf.fillStyle = vCtrlNum.bgColor;
        }
        // 绘制背景色
        this.ctxBuf.fillRect(vCtrlNum.x, vCtrlNum.y, vCtrlNum.w, vCtrlNum.h);

        // 获取DOM控件
        HMIDOMCtrlMG.getDisplayDOMCtrls(HMIPrj.vHMIPrj.curPg, vCtrlNum.id, "div", vDOMObject);
        // 检查外部控件的是否存在
        if (vDOMObject.obj) {
            // 设置临时显示用DOM控件的位置
            this.setDisplayDOMCtrlsPos(rect, vDOMObject.obj);
            // 检查是否为新控件，需要初始化
            // 外部控件注册 (表格控件注册)
            if (vDOMObject.new) {

                HMIExterCtrls.register(HG.ControlType.dataTable, vDOMObject.obj, this.editingMode, vCtrlNum, this.editingMode);
                // 外部控件设置
                // HMIExterCtrls.setParam(HG.ControlType.dataTable, vDOMObject.obj, 'testData', null);
                // 设置透明背景色
                vDOMObject.obj.style.backgroundColor = '#FFF0';
            } else {
                // 改变尺寸

                HMIExterCtrls.resize(HG.ControlType.dataTable, vDOMObject.obj, rect.w, rect.h);

                // 检查是否在执行模式下

                if (!this.editingMode) {
                    if (this.valueNew) {
                        HMIExterCtrls.register(HG.ControlType.dataTable, vDOMObject.obj, this.editingMode, vCtrlNum, this.editingMode);
                    }
                } else {

                    if (this.valueNew) {
                        // console.log(this.valueNew);
                        HMIExterCtrls.register(HG.ControlType.dataTable, vDOMObject.obj, this.editingMode, vCtrlNum, this.editingMode);
                    }
                }
            }
        }
    },
    // 绘制进度条
    drawProgressBar: function (vCtrlProgressBar) {
        // 进度条数据
        let vProgressBarData = 0;
        let rect = {
            x: vCtrlProgressBar.x,
            y: vCtrlProgressBar.y,
            w: vCtrlProgressBar.w,
            h: vCtrlProgressBar.h
        };
        let rectImage = { x: 0, y: 0, w: 0, h: 0 };
        // 获取图片
        let vImg    = null;
        // 数据获取
        let vMax    = Number(vCtrlProgressBar.max);
        let vMin    = Number(vCtrlProgressBar.min);
        let vUp     = Number(vCtrlProgressBar.limitUp);
        let vDn     = Number(vCtrlProgressBar.limitDn);

        // 检查控件类型
        if (vCtrlProgressBar.type != HG.ControlType.progressBar) return;
        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否为有效数据, 0时也为默认值
            if (vCtrlProgressBar.dataValue) {
                // 获取数据
                vProgressBarData = Number(vCtrlProgressBar.dataValue);
            } else {
                // 设置为零
                vProgressBarData = 0;
            }
        } else {
            // 设置为进度条数据值
            vProgressBarData = 0.6 * (vMax - vMin) + vMin;
        }

        // 检查进度条数据率
        if (vProgressBarData < vMin) vProgressBarData = vMin;
        if (vProgressBarData > vMax) vProgressBarData = vMax;
        // 设置为空
        vImg = null;
        // 检查背景图片是否存在
        if(vCtrlProgressBar.bgImage) {
            // 获取图片
            vImg = this.getImage(vCtrlProgressBar.bgImage);
        }
        // 检查背景图片是否存在
        if(vImg) {
            // 绘制背景图
            this.ctxBuf.drawImage(vImg, rect.x, rect.y, rect.w, rect.h);
        } else {
            // 颜色
            this.ctxBuf.fillStyle = vCtrlProgressBar.bgColor;
            // 绘制矩形
            this.ctxBuf.fillRect(rect.x, rect.y, rect.w, rect.h);
        }

        // 计算位置
        if (HG.AttrSelType.progressBarMode.horizontal == vCtrlProgressBar.mode) {
            // 宽度计算
            rect.w = (vProgressBarData - vMin) * rect.w / (vMax - vMin);
        } else {
            // 高度计算
            let vRealHeight = (vProgressBarData - vMin) * rect.h / (vMax - vMin);
            // 计算起始位置
            rect.y = rect.y + rect.h - vRealHeight;
            // 高度重置
            rect.h = vRealHeight;
        }
        
        // 设置为空
        vImg = null;
        // 检查前景图片
        if(vCtrlProgressBar.foreImage) {
            // 获取图片
            vImg = this.getImage(vCtrlProgressBar.foreImage);
        }
        // 检查前景图片是否存在
        if(vImg) {
            // 获取前景图片的尺寸
            rectImage.w = vImg.width; rectImage.h = vImg.height;
            // 横向进度条
            if (HG.AttrSelType.progressBarMode.horizontal == vCtrlProgressBar.mode) {
                // 计算宽度
                rectImage.w = (vProgressBarData - vMin) * rectImage.w / (vMax - vMin);
            } else {
                // 高度计算
                let vRealHeight = (vProgressBarData - vMin) * rectImage.h / (vMax - vMin);
                // 计算起始位置
                rectImage.y = rectImage.y + rectImage.h - vRealHeight;
                // 高度重置
                rectImage.h = vRealHeight;
            }
            // 绘制前景图片
            this.ctxBuf.drawImage(vImg, rectImage.x, rectImage.y, rectImage.w, rectImage.h,
                                        rect.x, rect.y, rect.w, rect.h);
        } else {
            // 颜色
            this.ctxBuf.fillStyle = vCtrlProgressBar.foreColor;
            // 检查极限值是否有效
            if(vUp > vDn) {
                // 检查上限值是否有效
                if(vUp < vMax) {
                    // 检查上限值
                    if(vProgressBarData >= vUp) {
                        // 上限颜色
                        this.ctxBuf.fillStyle = vCtrlProgressBar.limitUpColor;
                    }
                }
                // 检查下限值是否有效
                if(vDn > vMin) {
                    // 检查下限值
                    if(vProgressBarData <= vDn) {
                        // 下限颜色
                        this.ctxBuf.fillStyle = vCtrlProgressBar.limitDnColor;
                    }
                }
            }
            // 绘制矩形
            this.ctxBuf.fillRect(rect.x, rect.y, rect.w, rect.h);

            // 绘制边框色
            this.ctxBuf.strokeStyle = vCtrlProgressBar.lineColor;
            this.ctxBuf.lineWidth   = 1;
            // 绘制矩形边框
            this.ctxBuf.strokeRect(vCtrlProgressBar.x, vCtrlProgressBar.y, vCtrlProgressBar.w, vCtrlProgressBar.h);
        }
    },
    // 绘制图片数值对应列表
    drawPictureList: function (vCtrlPictureList, vDrawPrjPageChanged) {
        // 图片数值显示状态
        let vViewStatus = 0;
        // 图片显示地址
        let vImgAddress = "";
        // 获取图片
        let vImg        = null;
        // 动画显示
        let vShowAnimate= false;
        // 动画显示索引
        let vAnimateIdx = 0;

        // 检查控件类型
        if (vCtrlPictureList.type != HG.ControlType.pictureList) return;
        // 检查是否为执行模式下
        if (!this.editingMode) {
            // 检查是否为有效数据, 0时也为默认值
            if (vCtrlPictureList.dataValue*1) {
                // 获取数据
                vViewStatus = Number(vCtrlPictureList.dataValue);
                // 动画显示
                vShowAnimate= true;
            } else {
                // 设置为零
                vViewStatus = 0;
                // 动画不显示
                vShowAnimate= false;
            }
        } else {
            // 设置为进度条数据值
            vViewStatus = vCtrlPictureList.viewStatus;
            // 动画显示
            vShowAnimate= true;
        }

        // 检查模式
        if(HG.AttrSelType.pictureListMode.animation == vCtrlPictureList.mode) {
            // 检查页面是否改变是否
            if (vDrawPrjPageChanged) {
                // 绘制每一张图片
                for(let lDrawIdx = 0; lDrawIdx < vCtrlPictureList.listValue.length; lDrawIdx++) {
                    // 获取图片
                    vImg = this.getImage(vCtrlPictureList.listValue[lDrawIdx]);
                    // 检查图片是否存在
                    if (vImg) {
                        // 绘制图片
                        this.ctxBuf.drawImage(vImg, vCtrlPictureList.x, vCtrlPictureList.y, vCtrlPictureList.w, vCtrlPictureList.h);
                    }
                    // 设置为空
                    vImg = null;
                }
            }
            // 动画显示
            if (vShowAnimate) {
                // 动画模式
                if (!vCtrlPictureList.animationIdx) {
                    // 清空
                    vCtrlPictureList.animationIdx = 0;
                }
                // 动画绘制索引递增
                vCtrlPictureList.animationIdx++;
                // 检查动画索引
                vAnimateIdx = vCtrlPictureList.animationIdx;
                vAnimateIdx -= (vCtrlPictureList.animationIdx % 3);
                vAnimateIdx /= 3;
                // 索引有效性检查
                if(vAnimateIdx < 0) vAnimateIdx = 0;
                if(vAnimateIdx >= vCtrlPictureList.listValue.length) {
                    // 重置索引
                    vAnimateIdx = 0;
                    // 动画绘制索引递增
                    vCtrlPictureList.animationIdx = 0;
                }
                // 设置图片显示递增
                vViewStatus = vAnimateIdx;
            }
        }

        // 图片显示地址
        vImgAddress = vCtrlPictureList.otherImage;
        // 获取图片地址
        if ((vViewStatus >= 0) && (vViewStatus < vCtrlPictureList.listValue.length)) {
            // 获取地址
            vImgAddress = vCtrlPictureList.listValue[vViewStatus];
        }

        // 设置为空
        vImg = null;
        // 检查是否有效
        if(vImgAddress) {
            // 检查背景图片是否存在
            if(vImgAddress != "") {
                // 获取图片
                vImg = this.getImage(vImgAddress);
            }
            // 检查图片是否存在
            if(vImg) {
                // 绘制图片
                this.ctxBuf.drawImage(vImg, vCtrlPictureList.x, vCtrlPictureList.y, vCtrlPictureList.w, vCtrlPictureList.h);
            }
        }
    },
    //-----------------------------------------------------------------------------
    // 绘制选择区域
    //
    drawSelectedArea: function () {
        // 保存
        this.ctxBuf.save();
        // 颜色
        this.ctxBuf.fillStyle = 'rgba(0, 128, 255, 0.2)';
        // 填充矩形
        this.ctxBuf.fillRect(this.selectedRect.l, this.selectedRect.t, this.selectedRect.w, this.selectedRect.h);
        // 颜色
        this.ctxBuf.strokeStyle = 'rgba(10, 10, 10, 0.2)';
        // line
        this.ctxBuf.lineWidth = 1;
        // 绘制矩形
        this.ctxBuf.strokeRect(this.selectedRect.l, this.selectedRect.t, this.selectedRect.w, this.selectedRect.h);
        // 恢复
        this.ctxBuf.restore();
    },
    //-----------------------------------------------------------------------------
    // 图片缓存获取
    //
    getImage: function (src) {
        var vData = null;
        // 查找是否存在
        for (let idx = 0; idx < HG.$Set.allImgsData.length; idx++) {
            // 检查是否一致
            if (src === HG.$Set.allImgsData[idx].src) {
                // 获取并退出
                vData = HG.$Set.allImgsData[idx];
                break;
            }
        }
        // 检查是否有效
        if (!vData) {
            // 创建新图片缓存
            vData = { idx: HG.$Set.allImgsData.length, src: src, url: src, img: null };
            // 添加
            HG.$Set.allImgsData.push(vData);
        }
        // 检查是否有效
        if (vData) {
            // 检查是否有效
            if (!vData.img) {
                // 检查是否存在上传图片
                vData.img = new Image;
                // 加载图片
                vData.img.src = vData.url;
            }

            return vData.img;
        }
        // 不存在
        return null;
    },
    //-----------------------------------------------------------------------------
    // 绘制画面控件
    //
    zoomCtrl: function (vCtrl, vPrjZoom) {
        // 根据控件不同进行处理
        vCtrl; vPrjZoom;
    },
    //-----------------------------------------------------------------------------
    // 计算DOM控件的尺寸
    //
    calcDOMCtrlsSize: function (rect) {
        // 缩放宽度、高度
        let vCurZoomX = (rect.x / this.settings.deviceZoom) * this.settings.displayZoom;
        let vCurZoomY = (rect.y / this.settings.deviceZoom) * this.settings.displayZoom;
        // 检查是否显示全部
        if ((this.settings.scrollPosLeftRate < 1) && (this.settings.scrollPosTopRate < 1)) {
            // 位置计算
            vCurZoomX -= (this.settings.scrollPosLeftRate * this.settings.width / this.settings.deviceZoom) * this.settings.displayZoom;
            vCurZoomY -= (this.settings.scrollPosTopRate * this.settings.height / this.settings.deviceZoom) * this.settings.displayZoom;
        }
        let vCurZoomW = (rect.w / this.settings.deviceZoom) * this.settings.displayZoom;
        let vCurZoomH = (rect.h / this.settings.deviceZoom) * this.settings.displayZoom;

        // 位置偏移
        vCurZoomX += this.rectCanvasArea.l;
        vCurZoomY += this.rectCanvasArea.t;

        // 设置到rect
        rect.x = vCurZoomX;
        rect.y = vCurZoomY;
        rect.w = vCurZoomW;
        rect.h = vCurZoomH;
    },
    //-----------------------------------------------------------------------------
    // 设置临时显示用DOM控件的位置
    //
    setDisplayDOMCtrlsPos: function (rect, vDOMCtrl) {
        // 计算DOM控件的尺寸
        this.calcDOMCtrlsSize(rect);

        // 设置显示状态
        vDOMCtrl.style.display = 'inherit';
        // 设置位置状态
        vDOMCtrl.style.position = 'absolute';
        // 详细的位置
        if ($store.state.isEditor) {
            vDOMCtrl.style.left = rect.x.toString() + 'px';
            vDOMCtrl.style.top = rect.y.toString() + 'px';
        } else {
            // 位置偏移
            rect.x -= this.rectCanvasArea.l;
            rect.y -= this.rectCanvasArea.t;
            // 位置偏移
            vDOMCtrl.style.left = rect.x.toString() + 'px';
            vDOMCtrl.style.top = rect.y.toString() + 'px';
        }

        vDOMCtrl.style.width = rect.w.toString() + 'px';
        vDOMCtrl.style.height = rect.h.toString() + 'px';
    },
    //-----------------------------------------------------------------------------
    // 刷新绘制画面中创建的临时DOM控件
    //
    refreshAllDrawDOMCtrls: function () {
        // 重置临时显示用的DOM控件用绘制层索引
        this.singleDisplayDomCtrlZIdx = 100;
        // 隐藏所有的DOM控件
        HMIDOMCtrlMG.hideAllDOMCtrls();
    },

    //-----------------------------------------------------------------------------
    // 绘制画面控件
    //
    drawCtrls: function (vPrj, drawStatus) {
        // 当前页面
        let vPage = vPrj.allPg[vPrj.curPg];
        // 控件数据
        let vCtrlData = null;
        // 控件选中数量
        let vSelectedCnt = 0;
        // 不同的缩放率
        let vDifferentZoom = false;
        // 项目文件中缩放率
        let vProjectZoom = false;

        // 检查项目的缩放率
        if (vPrj.setting.deviceZoom != this.settings.deviceZoom) {
            // 项目文件中缩放率
            vProjectZoom = vPrj.setting.deviceZoom;
            // 不同的缩放率
            vDifferentZoom = true;

            // 设置项目的缩放率
            vPrj.setting.deviceZoom = this.settings.deviceZoom;
        }

        // 上次绘制的页面索引
        if (this.lastDrawPageIdx != vPrj.curPg) {
            // 设置上次绘制的索引
            this.lastDrawPageIdx = vPrj.curPg;
            // 刷新绘制DOM控件
            this.refreshAllDrawDOMCtrls();

            // 页面改变
            drawStatus.pageChanged = true;
            // 绘制页面改变
            this.drawPrjPageChanged = true;
        }

        // 检查是否在执行模式下
        if (!this.editingMode) {
            // 当前页面刷新数据
            // HMIPrj.vHMIPrjFunc.refreshPageCtrlsData(vPage, this.drawPrjPageChanged);
        } else {
            // 刷新工具栏状态
            if (HG.$Set.projectStatus.toolbarStatus) {
                // 刷新工具栏状态
                HG.$Set.projectStatus.toolbarStatus = false;
                // 页面改变
                drawStatus.pageChanged = true;
            }
        }

        // 保存
        this.ctxBuf.save();
        // 移动偏移
        //this.ctxBuf.translate(0.5, 0.5);
        // 获取内容

        for (var idx = 0; idx < vPage.ctrls.length; idx++) {
            // 获取控件数据
            vCtrlData = vPage.ctrls[idx];

            if (vCtrlData.decimalPoint == undefined) {
                vCtrlData.decimalPoint = 0 * this.settings.domZoom;
            }
            // 检查缩放率
            if (vDifferentZoom) {
                // 缩放控件
                this.zoomCtrl(vCtrlData, vProjectZoom);
            }

            // 检查控件类型
            switch (vCtrlData.type) {
                // 直线控件
                case HG.ControlType.line:
                    // 绘制直线控件
                    this.drawLine(vCtrlData);
                    break;
                // 矩形控件
                case HG.ControlType.rect:
                    // 绘制矩形控件
                    this.drawRect(vCtrlData);
                    break;
                // 圆形控件
                case HG.ControlType.circle:
                    // 绘制圆形控件
                    this.drawCircle(vCtrlData);
                    break;
                // 弧控件
                case HG.ControlType.arc:
                    // 绘制弧控件
                    this.drawArc(vCtrlData);
                    break;
                // 文本控件
                case HG.ControlType.text:
                    // 绘制文本
                    this.drawText(vCtrlData);
                    break;
                // 图片控件
                case HG.ControlType.pic:
                    // 绘制图片
                    this.drawPicture(vCtrlData);
                    break;
                // 指示灯控件
                case HG.ControlType.light:
                    // 绘制指示灯
                    this.drawLight(vCtrlData);
                    break;
                // 日期时间控件
                case HG.ControlType.time:
                    // 绘制日期时间控件
                    this.drawTime(vCtrlData);
                    break;
                // 位按钮控件
                case HG.ControlType.bbtn:
                    // 绘制位按钮控件
                    this.drawBBtn(vCtrlData);
                    break;
                // 字按钮控件
                case HG.ControlType.wbtn:
                    // 绘制字按钮控件
                    this.drawWBtn(vCtrlData);
                    break;
                // 页面按钮控件
                case HG.ControlType.pbtn:
                    // 绘制页面按钮控件
                    this.drawPBtn(vCtrlData);
                    break;
                // 数值输入控件
                case HG.ControlType.num:
                    // 绘制数值输入控件
                    this.drawNumber(vCtrlData);
                    break;
                // 文数对应控件
                case HG.ControlType.textLst:
                    // 绘制文数对应控件
                    this.drawTextList(vCtrlData);
                    break;
                // 曲线控件
                case HG.ControlType.curves:
                    // 绘制曲线控件
                    this.drawCurves(vCtrlData);
                    break;
                // 柱状图
                case HG.ControlType.columns:
                    // 绘制柱状图控件
                    this.drawColumns(vCtrlData);
                    break;
                // 饼图
                case HG.ControlType.pies:
                    // 绘制饼图控件
                    this.drawPies(vCtrlData);
                    break;
                // 历史曲线
                case HG.ControlType.historyCurves:
                    // 绘制历史曲线
                    this.drawHistoryCurves(vCtrlData);
                    break;
                case HG.ControlType.warningTable:
                    // 绘制报警分页
                    this.drawWarningTable(vCtrlData);
                    break;
                case HG.ControlType.clockDial:
                    // 绘制温度表盘  
                    if (vCtrlData.symbol==undefined) {
                        vPage.ctrls[idx]["symbol"]="°C";
                    }
                    this.drawClockDial(vCtrlData);
                case HG.ControlType.dataTable:
                    // 绘制数据表格
                    this.drawDataTable(vCtrlData);
                    break;

                //
                // LEE 2022/04/24 以下为PC端上传专用控件 可以不添加到工具栏中.
                //
                case HG.ControlType.progressBar:
                    // 绘制进度条
                    this.drawProgressBar(vCtrlData);
                    break;
                // 图片数值对应列表
                case HG.ControlType.pictureList:
                    // 绘制图片数值对应列表
                    this.drawPictureList(vCtrlData, this.drawPrjPageChanged);
                    break;
            }
            // 检查是否处于编辑模式
            if (this.editingMode) {
                // 检查是否选中
                if (vCtrlData.selected) {
                    // 控件选中数量
                    vSelectedCnt++;
                    // 绘制外框 [mainSel 属性表示多选控件中的主控件，对齐参考控件]
                    this.drawFrame(vCtrlData.x, vCtrlData.y, vCtrlData.w, vCtrlData.h, vCtrlData.mainSel);
                }
            }
        }
        //---------------------------------------------------------------------
        // 绘制下拉列表
        //
        this.drawDropDownList(vPrj, this.drawPrjPageChanged);
        // 恢复
        this.ctxBuf.restore();
        this.valueNew = false;
        // 检查是否存在选择框
        if (this.selected) {
            // 检查是否点击在控件上
            if (!this.ctrlselected.selected) {
                // 绘制选择区域控件
                this.drawSelectedArea();
            }
        }

        // 绘制页面改变
        this.drawPrjPageChanged = false;

        // 控件选中数量
        return vSelectedCnt;
    },
    //-------------------------------------------------------------------------
    // 设置存在事件动作
    //
    setEventActive: function () {
        // 界面操作活动状态
        this.eventActivate = true;
        // 界面操作计数
        this.eventActivateCnt = this.settings.activeCntMax;
    },
    //-----------------------------------------------------------------------------
    // 获取刷新率对应的定时时间
    //
    getFpsInterval: function () {
        // 界面操作活动状态
        if (this.eventActivate) {
            // 检查界面操作计数
            if (this.eventActivateCnt > 0) {
                // 递减
                this.eventActivateCnt--;
            }
            // 检查界面操作计数
            if (0 === this.eventActivateCnt) {
                // 停止界面操作活动状态
                this.eventActivate = false;
            }
            // 返回状态
            return this.settings.fpsInterval;
        }
        else {
            // 返回状态
            return this.settings.fpsStaticInterval;
        }
    },
};

// 外部接口暴露
export default gDrawHandle;