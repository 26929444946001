// HMIDataPool 数据池管理
//
//  依赖
//      无
//
//  历史
//      创建    LEE     2021/04/28
//=====================================================================================
//-------------------------------------------------------------------------------------
// 数据池管理
//
import gWebSocket from "../../util/draw/HMIWebSocket";
import $store from '../../store/store';
import gEchoData from '../../util/draw/HMIEchoData';
import gInterface from '../../util/draw/HMIInterface';
import moment from 'moment';
import HMIPrj from "../../util/draw/HMIProject.js";
var gDataPoolManage = {
    //---------------------------------------------------------------------------------
    // 所有数据
    //
    // 数据格式: { gatewayId: 1, originalId: 2, parameterId: 3, data: 1},
    allData: [],
    promiseAllInterface: [],
    // 数据是否改变
    modified: false,
    //---------------------------------------------------------------------------------
    // 加载所有通讯数据
    //
    loadAllCOMMData: function (vCommData) {
        let vCommDataObj    = vCommData;
        // 通讯数据详细
        let vCommDetail     = null;
        // 查询通讯数据
        for (let vIdx = 0; vIdx < vCommDataObj.comms.length; vIdx++) {
            // 通讯数据详细

            vCommDetail = vCommDataObj.comms[vIdx];
            if (vCommDataObj.comms[vIdx].pageId != HMIPrj.vHMIPrj.curPg) return;
            // 检查是否有效
            if (vCommDetail) {
                // 设置通讯数据
                switch (vCommDetail.url) {
                    case 1:
                            if (gEchoData.wsData[vCommDetail.paramId]!=undefined) {
                        this.setCOMMDataDetail(vCommDetail.gatewayId,
                            vCommDetail.subOriginalId,
                            vCommDetail.paramId,
                            Number(gEchoData.wsData[vCommDetail.paramId].currentValue));
                            }
                            
                     
                        break;
                    case 2:  
                        this.allData.push({
                            parameterId:vCommDetail.gatewayId[0],
                            data: gEchoData.devidceData[gEchoData['paramskey'][vCommDetail.gatewayId[0] + ""]]                    
                        });
                        break;
                    case 3:
                        this.allData.push({
                            parameterId:'historyData',
                            data: gEchoData.historyData
                        });
                        break;
                    default:
                        break;
                }
               
               
            }
        }
    },
    //---------------------------------------------------------------------------------
    //完成通讯文件的数据接口
    //
    loadAllInerfaceData: function (currentPg) {
        let urlArr = {};
        let historyCondition = [];
        // let isH = false;
        let count = 0;
        gEchoData.vCommData.comms.forEach(element => {
            if (element.url) {
               if (currentPg == element.pageId) {
                    if (!urlArr[element.url] && element.url != 3) {
                        urlArr[element.url] = true
                        //调用接口
                        this.promiseAllInterface[count] = new Promise((resolve) => {
                            count++
                            this.implementItf(element.url, resolve, element.paramId)
                        })
                    } else {
                        if (element.url == 3) {
                            // let _arr = []       
                            let gatewayId = '';
                            let subOriginalId = '';
                            let params = []
                            element.paramId.forEach(element => {
                                if (element[0] != null & element[1] != null & element[2] != null) {
                                    gatewayId = element[0]
                                    subOriginalId=element[1]
                                    params.push(element[2]);
                                }
                               
                            });
                            // _arr.push({gatewayId,subOriginalId,params})
                            historyCondition.push({
                                ctrlId: element.ctrlId,
                                subOriginalId:subOriginalId,
                                paramIds:params,
                                // currentPage:1,
                            })
                           
                        }
                    }
                } 
            }
            
            
        });
        this.promiseAllInterface[count] = new Promise((resolve) => {
            count++
            gEchoData.historyObj = historyCondition
            this.implementItf(3, resolve, historyCondition, this.createTime())
        })
        
        setTimeout(()=>{
             $store.commit("setLoading", false)
            //分配数据
            this.loadAllCOMMData(gEchoData.vCommData);
        },1000)
           
        // Promise.all(this.promiseAllInterface).then(() => {

        //     $store.commit("setLoading", false)
        //     //分配数据
        //     this.loadAllCOMMData(gEchoData.vCommData);
        // })
    },
    //---------------------------------------------------------------------------------
    //处理时间格式
    //
    createTime: function () {
        let _date = moment(new Date()).format("yyyy-MM-DD")
       
        return _date 
    },
    //---------------------------------------------------------------------------------
    //通过key判断调用的接口方法
    //
    implementItf: function (url, resolve, param="",date) {
        let sWebSocketToken = $store.state.token;
        let sWebSocketTokenId = $store.state.tokenId;
        let projectId = $store.state.projectId;
        switch (url) {
            case 1:
                gWebSocket.openWebSocket(
                gWebSocket.makeUrl(sWebSocketToken, sWebSocketTokenId, projectId), resolve);
                break;
            case 2:
                //网关
                gInterface.projectDetail(projectId, resolve)
                break;
            case 3:  
                gInterface.getHistoryData(param, resolve,date)
                // gInterface.projectDetail(projectId, resolve)
                break;
            case 4:
                gInterface.getWarningTable(resolve)
                break;
            default:
                break;
        }

        
    },
    //---------------------------------------------------------------------------------
    // 查找通讯数据
    //
    searchCOMMData: function(gatewayId, originalId, parameterId) {
        // console.log(gatewayId, originalId, parameterId);
        // 数据节点
        let vDataNode = null;
        // 查询数据
        for(let vIdx = 0; vIdx < this.allData.length; vIdx++) {
            // 获取数据节点
            vDataNode = this.allData[vIdx];
            // 检查是否有效
            if(vDataNode) {
                // 检查是否存在
                if ((vDataNode.gatewayId === gatewayId) &&
                    (vDataNode.originalId === originalId) &&
                    (vDataNode.parameterId === parameterId)) {
                    // 返回数据
                    return vDataNode;
                } else if (gatewayId === null && originalId === null && parameterId === vDataNode.parameterId) {
                    return vDataNode;
                }
            }
        }
        // 返回空
        return null;
    },
    //---------------------------------------------------------------------------------
    // 设置通讯数据
    //
    setCOMMDataDetail: function (gatewayId, originalId, parameterId, data = 0) {
        // 查找通讯数据
        let vDataNode = this.searchCOMMData(gatewayId, originalId, parameterId);
        // 检查是否存在        
        if(null != vDataNode) {
            // 检查数据值是否一致
            if(vDataNode.data != data) {
                // 设置数据
                vDataNode.data = data;
                // 数据改变
                this.modified = true;
            }
        } else {
            // 添加数据
            this.allData.push({ gatewayId:      gatewayId, 
                                originalId:     originalId, 
                                parameterId:    parameterId, 
                                data:           data });
            // 数据改变
            this.modified = true;
        }
    },
    //---------------------------------------------------------------------------------
    // 设置通讯数据
    //
    setCOMMData: function(param, data=0) {

        let vItem = null;
        // 检查参数是否为数组
        if(!param.length) return;
        // 遍历参数
        for(let vIdx = 0; vIdx < param.length; vIdx++) {
            // 获取子项
            vItem = param[vIdx];
            // 检查子项是否为数组
            if(vItem.length === 3) {
                // 设置详细的通讯数据
                this.setCOMMDataDetail(vItem[0], vItem[1], vItem[2], data);
            }
        }
    },
    //---------------------------------------------------------------------------------
    // 获取通讯数据
    //
    loadCOMMData: function(param, defData=0) {
        let vItem = null;
        let vData = null;
        // 检查参数是否为数组
        if(!param.length) return;
        // 检查
        if(param[0].length) {
            // 遍历参数
            for(let vIdx = 0; vIdx < param.length; vIdx++) {
                // 获取子项
                vItem = param[vIdx];
                // 检查子项是否为数组
                if (3 == vItem.length) {
                    // 设置详细的通讯数据
                    vData = this.searchCOMMData(vItem[0], vItem[1], vItem[2]);
                    // 检查是否存在
                    if (vData) {
                        return vData.data;
                    }
                } else if (1 == vItem.length) {
                    vData = this.searchCOMMData(null, null, vItem[0]);
                    // 检查是否存在
                    if (vData) {
                        return vData.data;
                    }
                 }
            }
        } else if(3 == param.length) {
            // 设置详细的通讯数据
            vData = this.searchCOMMData(param[0], param[1], param[2]);
            // 检查是否存在
            if(vData) {
                return vData.data;
            }
        }

        // 返回默认值
        return defData;
    },
    //---------------------------------------------------------------------------------
    // 获取数据表格数据
    //
    loadTableData: function(param, defData=0) {
        let vItem = null;
        let vData = null;
        // 检查参数是否为数组
        if(!param.length) return;
        // 检查
            // 遍历参数
                // 获取子项
                vItem = param;
                // 检查子项是否为数组
                    // 设置详细的通讯数据
                    vData = this.searchCOMMData(vItem[0], vItem[1], vItem[2]);
                    
                    // 检查是否存在
                    if (vData) {
                        return vData.data;
                    }
        // 返回默认值
        return defData;
    },
};

// 外部接口暴露
export default gDataPoolManage;